import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_WALLET_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-wallet"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-wallet" : "/wallet-service"
    }`;

class WalletService {
  // 4/19/24 DEPRECATED
  // checkBalance(userId, version = 1, magicLinkToken = null) {
  //   const url = `${baseUrl}/balance/user/${userId}?version=${version}`;
  //   console.log(url);
  //   return (magicLinkToken ? oauthAxios : axios)
  //     .get(url)
  //     .then(response => {
  //       console.log("Wallet Balances: ", response.data.balances);
  //       return response.data.balances;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
  downloadTemplate() {
    return axios
      .get(baseUrl + "/wallet/bulk/template")
      .then(response => {
        console.log("Downloading template file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Payout_Template.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log(error);
      });
  }
  // 4/19/24 DEPRECATED
  // uploadPayoutFile(file, budgetId, description) {
  //   let formData = new FormData();
  //   console.log(budgetId);
  //   formData.append("budgetId", budgetId);
  //   formData.append("file", file);
  //   formData.append("description", description);
  //   return axios
  //     .post(baseUrl + "/wallet/payment/bulk/csv", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(response => {
  //       console.log(response.data);
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("There was an error");
  //       console.log(error.response.data);
  //       return error.response.data;
  //     });
  // }
  async validatePayoutFile(obj) {
    try {
      let formData = new FormData();
      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });
      // console.log(budgetId);
      // formData.append("budgetId", budgetId);
      // formData.append("file", file);
      // formData.append("description", description);
      console.log("Form data", formData);
      var response = await axios.post(
        baseUrl + "/wallet/payment/bulk/csv/validate",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return response.data;
    } catch (err) {
      console.log(
        "Error validating CSV ",
        err && err.response ? err.response.data || err.response : err
      );
      throw err && err.response ? err.response.data || err.response : err;
    }
  }
  fundBudget(budgetId, amount, source = null) {
    return axios
      .post(baseUrl + "/wallet/payment/budget/fund/" + budgetId, {
        amount: amount,
        source: source
      })
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("There was an error");
        console.log(error.response.data);
        return error.response.data;
      });
  }
  // updateCardActivity(cardActivityToken, body) {
  //   return axios
  //     .patch(baseUrl + "/cardActivity/" + cardActivityToken, body)
  //     .then(response => {
  //       console.log(response.data);
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("There was an error");
  //       console.log(error.response.data);
  //       return error.response.data;
  //     });
  // }
  sendPaymentBulk(data) {
    return axios
      .post(baseUrl + "/wallet/payment/bulk", data)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("There was an error");
        console.log(error.response.data);
        return error.response.data;
      });
  }

  // 4/19/24 DEPRECATED
  // createPaymentRule(data) {
  //   return axios
  //     .post(baseUrl + "/wallet/paymentRule", data)
  //     .then(response => {
  //       console.log(response.data);
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log(
  //         "There was an error creating payment rule ",
  //         error.response.data
  //       );
  //       throw (error.response && error.response.data) || error;
  //     });
  // }

  updateReportingBalancesStellar() {
    return axios
      .get(baseUrl + "/balance/cron/stellar")
      .then(response => {
        console.log("Wallet Balance CRON: ", response.data.balances);
        return response.data.balances;
      })
      .catch(error => {
        console.log(error);
      });
  }
  updateReportingBalancesMarqetaUsers() {
    return axios
      .get(baseUrl + "/balance/cron/marqeta/users")
      .then(response => {
        console.log("Wallet Balance CRON: ", response.data.balances);
        return response.data.balances;
      })
      .catch(error => {
        console.log(error);
      });
  }
  updateReportingBalancesProgramBalance() {
    return axios
      .get(baseUrl + "/balance/cron/marqeta/program")
      .then(response => {
        console.log("Wallet Balance CRON: ", response.data.balances);
        return response.data.balances;
      })
      .catch(error => {
        console.log(error);
      });
  }
  // runProvisionalRuleCRON() {
  //   return axios
  //     .get(baseUrl + "/wallet/paymentRule/cron")
  //     .then(response => {
  //       console.log("Payment Rule CRON: ", response.data);
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
  // runClawbackCRON() {
  //   return axios
  //     .get(baseUrl + "/wallet/paymentRule/clawback/cron")
  //     .then(response => {
  //       console.log("Clawback CRON: ", response.data);
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
  // Used by per diems
  createPaymentRulesBulk(data) {
    return axios
      .patch(baseUrl + "/wallet/paymentRule/bulk", data)
      .then(response => {
        console.log(
          "PaymentRule creation/update response: ",
          response.data.paymentRules
        );
        return response.data.paymentRules;
      })
      .catch(error => {
        console.log(error);
      });
  }
  // 4/19/24 DEPRECATED
  // getPaymentRules() {
  //   return axios
  //     .get(baseUrl + "/wallet/paymentRule")
  //     .then(response => {
  //       console.log("PaymentRule response: ", response.data.parentPaymentRule);
  //       return response.data.parentPaymentRule;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
  // 4/19/24 DEPRECATED
  // deletePaymentRules(paymentRuleIds) {
  //   var ids = paymentRuleIds.map(x => "id=" + encodeURIComponent(x)).join("&");
  //   return axios
  //     .delete(baseUrl + "/wallet/paymentRule/" + ids)
  //     .then(response => {
  //       console.log("PaymentRule response: ", response.data.paymentRule);
  //       return response.data.paymentRule;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }
  getMCCGroups() {
    return axios
      .get(baseUrl + "/mcc/groupDefinition")
      .then(response => {
        console.log(
          "MCC Group Definition response: ",
          response.data.mccGroupDefinition
        );
        return response.data.mccGroupDefinition;
      })
      .catch(error => {
        console.log(error);
      });
  }
  getMCCs() {
    return axios
      .get(baseUrl + "/mcc")
      .then(response => {
        console.log("MCC response: ", response.data.mcc);
        return response.data.mcc;
      })
      .catch(error => {
        console.log(error);
      });
  }
  createBudgetTransfer(data) {
    return axios
      .post(baseUrl + "/wallet/payment/budget", data)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("There was an error");
        console.log(error.response.data);
        return error.response.data;
      });
  }
  createBudgetFundingRequest(data) {
    return axios
      .post(baseUrl + "/wallet/request/budget", data)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("There was an error");
        console.log(error.response.data);
        return error.response.data;
      });
  }
  updateBudgetFundingRequest(id, status) {
    return axios
      .patch(baseUrl + "/wallet/request/" + id, {
        status: status
      })
      .then(response => {
        console.log(response.data.budgetActivity);
        return response.data.budgetActivity;
      })
      .catch(error => {
        console.log("There was an error", error);
        return error.response
          ? error.response.data
            ? error.response.data
            : error.response
          : error;
      });
  }
  recatagorizeTransaction(cardActivityToken, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/transaction/recategorize/" + cardActivityToken)
      .then(response => {
        console.log(response);
        // console.log(response.data);
        console.log(
          "Recatagorized transaction ",
          response.data.data[0].cardActivityToken
        );
        return response.data.data[0].cardActivityToken;
      })
      .catch(error => {
        console.log("Error recatagorizing transaction ", error);
        return error.response
          ? error.response.data
            ? error.response.data
            : error.response
          : error;
      });
  }
  // V2 Methods
  getPaymentRulesV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/payment-rule", {
        params: params
      })
      .then(response => {
        console.log("PaymentRule response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response);
        throw error.response;
      });
  } // getPaymentRulesV2

  getParentPaymentRulesV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/parent-payment-rule", {
        params: params
      })
      .then(response => {
        console.log("ParentPaymentRule response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response);
        throw error.response;
      });
  } // getParentPaymentRulesV2

  getPaymentPresetsV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/payment-preset", {
        params: params
      })
      .then(response => {
        console.log("ParentPaymentPreset response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error getting payment presets: ", error.response);
        throw error.response;
      });
  } // getPaymentPresetsV2

  getRecognitionPaymentPresetsV2(clientId, screen, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/payment-preset", {
        params: {
          clientId,
          screen
        }
      })
      .then(response => {
        console.log("ParentPaymentPreset response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error getting payment presets: ", error.response);
        throw error.response;
      });
  } // getRecognitionPaymentPresetsV2

  createPaymentPresetV2(body, params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/payment-preset", body, { params })
      .then(response => {
        console.log("createPaymentPresetV2 response: ", response.data);
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating payment preset: ", error.response);
        throw error.response;
      });
  } // createPaymentPresetV2

  updatePaymentPresetV2(id, body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .patch(`${baseUrl}/v2/payment-preset/${id}`, body)
      .then(response => {
        console.log("updatePaymentPresetV2 response: ", response.data);
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating payment preset: ", error.response);
        throw error.response;
      });
  } // updatePaymentPresetV2

  createPaymentV2(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/parent-payment-rule", body)
      .then(response => {
        console.log("POST ParentPaymentRule response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error when creating payment", error.response);
        throw error.response ? error.response.data || error.response : error;
      });
  } // createPaymentV2

  createPaymentConfigV2(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/parent-payment-rule-config", body)
      .then(response => {
        console.log("POST ParentPaymentRuleConfig response: ", response.data);
        return response.data;
      })
      .catch(e => {
        console.log("Error when creating payment config", e.response);
        throw e && e.response ? e.response.data || e.response : e;
      });
  } // createPaymentConfigV2

  replacePaymentConfigV2(id, body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .put(`${baseUrl}/v2/parent-payment-rule-config/${id}`, body)
      .then(response => {
        console.log("PUT ParentPaymentRuleConfig response: ", response.data);
        return response.data;
      })
      .catch(e => {
        console.log("Error when creating payment config", e.response);
        throw e && e.response ? e.response.data || e.response : e;
      });
  } // replacePaymentConfigV2

  getParentPaymentRuleConfigsV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/parent-payment-rule-config", {
        params: params
      })
      .then(response => {
        console.log("ParentPaymentRuleConfig response: ", response.data);
        return response.data.result;
      })
      .catch(error => {
        console.log(error.response);
        throw error.response;
      });
  } // getParentPaymentRuleConfigsV2

  updateParentPaymentRuleConfigFailuresV2(id, body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .patch(`${baseUrl}/v2/parent-payment-rule-config-failure`, body, {
        params: { filter: `parentPaymentRuleConfigFailureId = ${id}` }
      })
      .then(response => {
        console.log(
          "PATCH ParentPaymentRuleConfigFailure response: ",
          response.data
        );
        return response.data;
      })
      .catch(e => {
        console.log("Error when updating payment config failure", e.response);
        throw e && e.response ? e.response.data || e.response : e;
      });
  } // replacePaymentConfigV2

  getParentPaymentRuleConfigFailuresV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/parent-payment-rule-config-failure", {
        params: params
      })
      .then(response => {
        console.log(
          "GET ParentPaymentRuleConfigFailure response: ",
          response.data
        );
        return response.data.result;
      })
      .catch(error => {
        console.log(error.response);
        throw error.response;
      });
  } // getParentPaymentRuleConfigsV2

  refreshBudgetBalance(budgetId, clientId) {
    return axios
      .post(
        baseUrl + "/v2/wallet/budget/balance/refresh",
        {
          budgetId,
          clientId
        },
        {
          headers: {
            "content-type": "text/plain"
          }
        }
      )
      .then(response => {
        console.log("Budget balance response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error refreshing budget", error.response);
        throw error.response;
      });
  } // getParentPaymentRuleConfigsV2

  checkUserBalanceV2(userId, magicLinkToken = null) {
    const url = `${baseUrl}/v2/wallet/balance/user/${userId}`;
    return (magicLinkToken ? oauthAxios : axios)
      .get(url)
      .then(response => {
        console.log("Wallet Balances (V2): ", response.data.result);
        return response.data.result;
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export default new WalletService();
