<template>
  <div class="full-height ">
    <v-card width="100%" rounded="0" class="full-height whistle-gradient">
      <!-- <div
        id="header"
        class="d-flex justify-space-between align-center"
        width="100%"
      >
        <v-card-title class="brand--text font-weight-bold">
          <div
            class="d-flex align-center cursor-pointer mr-4"
            v-if="!showRobin"
            @click="showRobin = true"
          >
            <Robin
              :showText="false"
              :width="50"
              :height="50"
              animation="blinking"
              :loop="false"
            />
          </div>
          Activity
        </v-card-title>
        <div v-if="devEnvironment">
          (Total Records: {{ this.messageOptions.count }}) (Total in Memory:
          {{ computedVisibleMessages.length }})
          {{ backgroundActivityRefresh ? "Bkgrnd Task" : "" }}
          <v-text-field
            v-model="backgroundRefreshTimeout"
            type="number"
            label="Timeout (ms)"
          />
        </div>
      </div>
      <v-divider class="mx-4" /> -->

      <v-row class="mt-0 mx-0">
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
          xl="3"
          :class="{ 'pl-6 pr-0': !isMobile }"
        >
          <div
            v-if="!magicLinkToken"
            class="white--text d-flex justify-start full-width align-center pt-2 recognition-recommendation cursor-pointer"
            :class="{ 'mt-5': isMobile, 'mt-16': !isMobile }"
            @click="loadRecognition"
          >
            <div class="recognition-emoji-outer-circle">
              <div class="recognition-emoji-inner-circle">
                <v-icon color="white" large class="pb-0 mb-0 1recognition-emoji"
                  >mdi-party-popper</v-icon
                >
              </div>
            </div>
            <p class="pb-0 mb-0 ml-3">Recognize someone</p>
          </div>
          <v-card
            class="rounded-lg pa-3 mt-10"
            elevation="0"
            v-if="loadingRobin || robinRecommendations.length"
          >
            <div class="d-flex full-width justify-space-between align-center">
              <div class="d-flex align-start">
                <Robin
                  :showText="false"
                  :width="50"
                  :height="50"
                  animation="blinking"
                  :loop="false"
                  class="mt-1"
                />
                <div class="d-flex flex-column text-left ml-0">
                  <v-card-title class="font-weight-bold word-break pt-1">
                    <span class="word-break"
                      >Good {{ timeOfDay }}, {{ userProfile.firstName }}!
                    </span>
                  </v-card-title>
                  <v-card-subtitle>A few suggestions for you</v-card-subtitle>
                </div>
              </div>
            </div>
            <div v-if="loadingRobin">
              <v-progress-circular
                indeterminate
                size="40"
                :width="3"
                color="brandCyan"
                class="mr-1"
              ></v-progress-circular>
            </div>
            <v-card
              v-for="card in robinRecommendations"
              :key="card.recommendationId"
              elevation="0"
              class="px-3 mt-2 text-left px-3 d-flex flex-column justify-space-between rounded-lg robin-recommendation"
              :color="
                card.context == 'recognition' && card.recommendationId == -1
                  ? 'brandDarkCyan'
                  : undefined
              "
              @click="loadRecommendation(card.recommendationId, false)"
            >
              <div>
                <div class="d-flex justify-space-between pt-3 pb-3 word-break">
                  <div class="d-flex align-start pr-2">
                    <v-icon
                      color="white"
                      class="rounded robin-icon mr-4"
                      :class="{
                        'brand-dark-green-background': [
                          'wallet',
                          'wallet_use'
                        ].includes(card.context),
                        'brand-background': [
                          'people',
                          'profile',
                          'mobile-popup',
                          'budgets',
                          'programs',
                          'learning'
                        ].includes(card.context),
                        'brand-cyan-background': card.context == 'recognition'
                      }"
                      >{{ card.icon }}</v-icon
                    >
                    <span
                      style="line-height: 22px; font-size: 1.1em;"
                      class="mt-0 text-body-1"
                      v-html="card.title"
                    ></span>
                  </div>
                  <v-icon>mdi-chevron-right</v-icon>
                </div>
              </div>
            </v-card>
          </v-card>
          <!-- <div
            class="d-flex full-width mt-2 justify-space-between align-center"
            v-if="!isMobile && adminRecommendations.length"
          >
            <div class="d-flex align-center">
              <v-card-title
                class="font-weight-bold word-break pl-0 ml-0 mb-0 pb-1"
              >
                <strong class="word-break font-weight-bold"
                  >Admin Quick Links
                </strong>
              </v-card-title>
            </div>
          </div> -->

          <!-- <div v-if="!isMobile">
            <div
              v-for="card in adminRecommendations"
              :key="card.recommendationId"
              class="my-2 text-left full-width d-flex flex-column justify-space-between rounded-lg cursor-pointer"
              @click="loadRecommendation(card.recommendationId, true)"
            >
              <div class="d-flex justify-start word-break ">
                <div class="d-flex align-center">
                  <v-icon class="rounded robin-icon mr-2" color="black">{{
                    card.icon
                  }}</v-icon>
                  <strong
                    style="line-height: 22px; font-size: 1.1em;"
                    v-html="card.title"
                  ></strong>
                </div>
              </div>
            </div>
          </div> -->
        </v-col>
        <!-- 12, 12, 7, 6, 6, -->
        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="9"
          xl="9"
          :class="{ 'message-feed': !isMobile }"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="10"
              lg="9"
              xl="8"
              :class="isMobile ? 'pt-0 px-0' : ''"
            >
              <div
                class="d-flex full-width justify-space-between align-center white--text text-left mb-4"
                :class="{
                  'px-12': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
                }"
              >
                <h2 class="font-weight-bold word-break">
                  Activity
                </h2>
                <v-btn
                  v-if="devEnvironment"
                  @click="display.debug = !display.debug"
                  depressed
                  text
                  color="white"
                  class="white--text"
                  ><v-icon>mdi-bug</v-icon>Debug (Dev)</v-btn
                >
                <v-menu
                  v-model="display.filterMenu"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      text
                      color="white"
                      class="white--text"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-filter</v-icon>Filters</v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.wallet"
                          @change="filterActivity()"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>Wallet</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('wallet')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.recognition_recipient"
                          @change="filterActivity('recognition_recipient')"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>My Recognitions</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('recognition_recipient')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.recognition"
                          @change="filterActivity('recognition')"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>All Recognitions</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('recognition')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.program"
                          @change="filterActivity('program')"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>Program</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('program')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.news"
                          @change="filterActivity('news')"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>News</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('news')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.general"
                          @change="filterActivity('general')"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content class="text-left">
                        <v-list-item-title>General</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('general')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-checkbox
                          color="brandCyan"
                          :true-value="true"
                          :false-value="false"
                          v-model="filters.alerts"
                          @change="filterActivity('alerts')"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>Alerts</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        ><v-btn
                          text
                          small
                          color="brandCyan"
                          @click="loadOneFilter('alerts')"
                          >Only</v-btn
                        ></v-list-item-action
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                class="d-flex flex-column justify-center white--text pt-3"
                v-if="loadingActivity"
              >
                <h3>Loading your activity...</h3>
                <div class="mx-auto pt-3">
                  <!-- <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="swayAndBounce"
                :loop="true"
              /> -->
                  <v-progress-circular
                    indeterminate
                    size="40"
                    :width="3"
                    color="white"
                    class="mr-1"
                  ></v-progress-circular>
                </div>
              </div>
              <div
                v-else-if="!loadingActivity && !messages.length"
                class="mt-3 white--text"
              >
                <b
                  class="word-break"
                  v-if="
                    !filters.program &&
                      !filters.news &&
                      !filters.general &&
                      !filters.wallet &&
                      !filters.recognition &&
                      !filters.recognition_recipient &&
                      !filters.alerts
                  "
                  >Select one of the filters to view your activity
                </b>
                <b class="word-break" v-else
                  >Looks like there isn't much activity for you right now. Check
                  back later for more!
                </b>
              </div>
              <v-container
                v-else
                class="full-width d-flex flex-wrap flex-column"
                :class="{
                  'px-12': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg,
                  'px-0': isMobile
                }"
              >
                <div v-if="display.debug" class="white--text">
                  (Total Records: {{ this.messageOptions.count }}) (Total in
                  Memory: {{ computedVisibleMessages.length }})
                  {{ backgroundActivityRefresh ? "Bkgrnd Task" : "" }}
                  <v-text-field
                    v-model="backgroundRefreshTimeout"
                    type="number"
                    color="white"
                    label="Timeout (ms)"
                  />
                </div>
                <v-progress-linear
                  v-if="refreshingActivity && messages.length > 0"
                  indeterminate
                  color="white"
                  class="mb-2"
                ></v-progress-linear>

                <div class="d-flex flex-column card-column-old full-width">
                  <div
                    v-for="msg in computedVisibleMessages"
                    :key="msg.messageLogId"
                    class="mb-3 activity-card"
                  >
                    <ActivityFeedCard
                      :key="msg.messageLogId"
                      :id="msg.contextResourceId"
                      :messageId="msg.messageId"
                      :icon="msg.icon"
                      :iconColor="msg.iconColor"
                      :context="msg.context"
                      :subcontext="msg.subcontext"
                      :title="msg.cardTitle"
                      :date="msg.date"
                      :messageTitle="msg.messageSubject"
                      :messageSubtitle="msg.messageShortBody"
                      :messageBody="msg.messageBody"
                      :messageSuccessImage="msg.successImage"
                      :image="msg.imageURL"
                      :linkText="msg.linkText"
                      :object="msg"
                      :messageTitleFlag="msg.titleFlag"
                      :messageSubtitleFlag="msg.subtitleFlag"
                      :messageIconFlag="msg.messageIconFlag"
                      :robinFlag="false"
                      :imageFlag="msg.displayImageInCardPreview"
                      :progressFlag="msg.progressFlag"
                      :likedByUser="msg.likedByUser"
                      :linkButtonFlag="true"
                      :fullMessage="msg.context == 'recognition'"
                      :comments="msg.comments"
                      :reactions="msg.reactions"
                      :values="msg.values"
                      :recipients="msg.recipients"
                      :privateRecognition="msg.privateRecognition"
                      :newMessage="!msg.readOnDashboard"
                      @create-comment="createMessageComment($event, false)"
                      @remove-comment="
                        removeMessageComment($event, msg.messageId, false)
                      "
                      @create-reaction="
                        createMessageReaction(msg.messageId, $event, false)
                      "
                      @remove-reaction="
                        removeMessageReaction(msg.messageId, $event, false)
                      "
                    />
                  </div>
                </div>

                <div class="d-flex full-width justify-center">
                  <v-btn
                    v-if="showMoreButton == true"
                    text
                    color="darkGray"
                    class="mt-4 darkGray--text"
                    @click="loadMoreMessages"
                    :disabled="displayLoadingSpinner"
                  >
                    <v-icon class="mr-1" v-if="displayLoadingSpinner == false"
                      >mdi-plus</v-icon
                    >
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="20"
                      :width="3"
                      color="darkGray"
                      class="mr-1"
                    ></v-progress-circular>
                    View More
                  </v-btn>
                </div>
              </v-container></v-col
            >
          </v-row>
        </v-col>
        <!-- <v-col
          v-if="!isMobile && adminRecommendations.length"
          cols="0"
          sm="0"
          md="3"
          lg="3"
          xl="3"
        >
          <div
            class="d-flex full-width mt-2 justify-space-between align-center"
          >
            <div class="d-flex align-center">
              <v-card-title
                class="font-weight-bold word-break pl-0 ml-0 mb-0 pb-1"
              >
                <span class="word-break">Admin Quick Links </span>
              </v-card-title>
            </div>
          </div>
          <v-card
            v-for="card in adminRecommendations"
            :key="card.recommendationId"
            elevation="2"
            width="100%"
            class="my-2 text-left px-4 d-flex flex-column justify-space-between rounded-lg"
            @click="loadRecommendation(card.recommendationId, true)"
          >
            <div>
              <div
                class="d-flex justify-space-between pt-4 pb-2 mb-2 word-break "
              >
                <div class="d-flex align-center pr-2">
                  <v-icon
                    color="white"
                    class="rounded robin-icon mr-4"
                    :class="{
                      'brand-dark-green-background': [
                        'wallet',
                        'wallet_use'
                      ].includes(card.context),
                      'brand-background': [
                        'people',
                        'profile',
                        'mobile-popup',
                        'budgets',
                        'programs',
                        'learning'
                      ].includes(card.context),
                      'brand-cyan-background': card.context == 'recognition'
                    }"
                    >{{ card.icon }}</v-icon
                  >
                  <span
                    style="line-height: 22px; font-size: 1.1em;"
                    v-html="card.title"
                  ></span>
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </div>
            </div>
          </v-card>
        </v-col> -->
      </v-row>
    </v-card>
    <v-dialog v-model="devError" width="400">
      <v-card class="py-6">
        Error hitting an endpoint while running the {{ devErrorType }} function.
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="displayPopup"
      width="600"
      persistent
      content-class="elevation-0 mx-3 px-0 recognition-popup-dialog"
      class=""
    >
      <div class="d-flex align-center justify-center px-1 full-height">
        <div class="d-flex flex-column align-center full-height">
          <div
            class="d-flex justify-space-between align-center full-width mb-5"
          >
            <v-btn
              fab
              small
              class="elevation-0 hidden"
              color="grey"
              @click="
                dismissMessage(currentPopup.messageLogId, true, false, false)
              "
              ><v-icon color="white" dark>mdi-close</v-icon></v-btn
            >
            <div class="d-flex align-center">
              <v-btn
                icon
                small
                :class="{ hidden: currentPopupIndex == 0 }"
                class="mr-1"
                @click="
                  dismissMessage(currentPopup.messageLogId, false, true, false)
                "
                ><v-icon color="white" x-large>mdi-chevron-left</v-icon></v-btn
              >
              <div
                class="white-background pl-2 pr-3 py-1 rounded d-flex align-center"
                v-if="popups.length > 1 || $vuetify.breakpoint.xs"
              >
                <h3 class=" black--text text-lg font-italic mx-1 mt-1">
                  {{ currentPopupIndex + 1 }} of {{ popups.length }}
                </h3>
              </div>
              <v-btn
                icon
                small
                :class="{ hidden: currentPopupIndex >= popups.length - 1 }"
                class="ml-1"
                @click="
                  dismissMessage(currentPopup.messageLogId, false, false, true)
                "
                ><v-icon color="white" x-large>mdi-chevron-right</v-icon></v-btn
              >
            </div>
            <v-btn
              fab
              x-small
              class="elevation-0"
              color="grey"
              @click="
                dismissMessage(currentPopup.messageLogId, true, false, false)
              "
              ><v-icon color="white" dark>mdi-close</v-icon></v-btn
            >
          </div>
          <ActivityFeedCard
            v-if="currentPopup"
            :elevation="0"
            width="400"
            maxWidth="calc(100vw - 40px)"
            :key="currentPopup.messageLogId"
            :id="currentPopup.contextResourceId"
            :messageId="currentPopup.messageId"
            :icon="currentPopup.icon"
            :iconColor="currentPopup.iconColor"
            :context="currentPopup.context"
            :subcontext="currentPopup.subcontext"
            :title="currentPopup.cardTitle"
            :date="currentPopup.date"
            :messageTitle="currentPopup.messageSubject"
            :messageSubtitle="currentPopup.messageShortBody"
            :messageBody="currentPopup.messageBody"
            :messageSuccessImage="currentPopup.successImage"
            :image="currentPopup.imageURL"
            :linkText="currentPopup.linkText"
            :object="currentPopup"
            :messageTitleFlag="currentPopup.titleFlag"
            :messageSubtitleFlag="currentPopup.subtitleFlag"
            :messageIconFlag="currentPopup.messageIconFlag"
            :robinFlag="false"
            :imageFlag="currentPopup.displayImageInCardPreview"
            :likedByUser="currentPopup.likedByUser"
            :linkButtonFlag="true"
            :popup="true"
            :fullMessage="currentPopup.context == 'recognition'"
            :comments="currentPopup.comments"
            :reactions="currentPopup.reactions"
            :values="currentPopup.values"
            :celebration="currentPopup.celebrationEffect"
            :recipients="currentPopup.recipients"
            :privateRecognition="currentPopup.privateRecognition"
            @create-comment="createMessageComment($event, true)"
            @remove-comment="
              removeMessageComment($event, currentPopup.messageId, true)
            "
            @create-reaction="
              createMessageReaction(currentPopup.messageId, $event, true)
            "
            @remove-reaction="
              removeMessageReaction(currentPopup.messageId, $event, true)
            "
            :class="{
              'card-stack': popups.length > 1 && popups.length <= 2,
              'card-stack-multiple': popups.length > 2
            }"
          />
        </div></div
    ></v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import CommunicationService from "../services/CommunicationService.js";
import RobinService from "../services/RobinService.js";
import ActivityFeedCard from "@/components/ActivityFeedCardV2.vue";
import Robin from "@/components/Robin.vue";
import { mapState } from "vuex";
import momentTz from "moment-timezone";

export default {
  name: "Activity",
  title: "Activity | Whistle",
  components: {
    ActivityFeedCard,
    Robin
  },
  data() {
    return {
      backgroundRefreshTimeout: 30000,
      loadingActivity: true,
      backgroundActivityRefresh: false,
      search: null,
      display: { filters: false, debug: false, filterMenu: false },
      filters: {
        program: true,
        learning: false,
        survey: false,
        wallet: true,
        recognition: true,
        recognition_recipient: false,
        news: true,
        general: true,
        robin: false,
        alerts: true
      },
      refreshingActivity: false,
      messages: [],
      popups: [],
      maxPopupId: null,
      activityPageSize: 20,
      openMessage: null,
      showRobin: true,
      refreshTimeout: null,
      lastPopupRefresh: null,
      lastActivityRefresh: null,
      recognitionAdmin: {
        recommendationId: -1,
        icon: "mdi-party-popper",
        color: "brandGreen",
        context: "recognition",
        permission: "vue:read:recognitionwizard",
        title: "Recognize someone!",
        subtitle: "Take one minute to personally recognize a teammate."
      },
      adminOptions: [
        {
          recommendationId: 0,
          icon: "mdi-credit-card-outline",
          color: "brandDarkGreen",
          context: "wallet",
          permission: "wallets:create:cashpayment",
          title: "Send a one time payment",
          subtitle:
            "Send a single payment to one or many people with the option to restrict funds for a specific purpose."
        },
        {
          recommendationId: 1,
          icon: "mdi-star",
          color: "brand",
          context: "programs",
          permission: "vue:read:programwizard",
          title: "Build a new program",
          subtitle:
            "Introduce new employees to the company! Well-trained employees leads to higher productivity."
        },
        {
          recommendationId: 5,
          icon: "mdi-account-multiple",
          color: "brand",
          context: "people",
          permission: "vue:read:people",
          title: "Invite your team to join Whistle",
          subtitle: "Onboard your team to Whistle so everyone can benefit!"
        },
        {
          recommendationId: 2,
          icon: "mdi-calculator-variant",
          color: "brandDarkGreen",
          context: "budgets",
          permission: "vue:read:budgets",
          title: "Fund a wellness or culture budget",
          subtitle:
            "Build a more inclusive culture by enabling a more diverse set of experiences at work."
        },
        {
          recommendationId: 3,
          icon: "mdi-star",
          color: "brand",
          context: "learning",
          permission: "vue:read:learningbuilder",
          title: "Create a new learning module",
          subtitle:
            "A good onboarding learning module can improve retention and productivity in your company."
        },
        {
          recommendationId: 4,
          icon: "mdi-wallet",
          color: "brandDarkGreen",
          context: "wallet",
          permission: "wallets:create:cashpayment",
          title: "Buy lunch for your remote team members",
          subtitle:
            "Build a more united team by rewarding your remote team members to make them feel appreciated."
        }
      ],
      recommendations: [],
      loadingRobin: false,
      messageOptions: {
        filter: null,
        page: 1,
        total: 0,
        count: 0
      },
      displayLoadingSpinner: false,
      devError: false,
      devErrorType: null,
      currentPopupIndex: null,
      displayPopup: false
    };
  },
  created() {
    // this.$store.dispatch("setUnreadMessages");
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onScreenResize);
    });
    if (!this.magicLinkToken) this.getRecommendations();
    this.getActivity(true, true);
    this.getPopupMessages();

    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-62px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onScreenResize);
    if (this.refreshTimeout) {
      console.log("Clearing refresh timeout");
      clearTimeout(this.refreshTimeout);
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    async getRecommendations() {
      this.loadingRobin = true;
      try {
        var startTime = new Date();

        var recs = await RobinService.getRecommendationsByUser();
        console.log("Recommendations: ", recs);

        // We check the time elapsed since sending the request. If the user has been waiting less than 1.5 seconds, we show the animation so it doesn't flash on the screen
        var timeDiff = momentTz().diff(momentTz(startTime));
        if (timeDiff >= 1500) timeDiff = 1500;
        setTimeout(() => {
          this.loadingRobin = false;
          this.recommendations = recs.map(x => {
            x.progressPercent = parseFloat(x.progressPercent || 0);
            return x;
          });
        }, 1500 - timeDiff);
      } catch (err) {
        console.log("Error getting recs ", err);
        this.reccomendations = [];
        this.loadingRobin = false;
      }
    },
    async getActivity(displayLoading = true, reset = false, refresh = false) {
      console.log("getActivity() ", { displayLoading, reset, refresh });
      if (this.computedFilterString != null) {
        try {
          if (reset == true) this.messageOptions.page = 1;
          // If displayLoading is true it displays the robin icon
          this.loadingActivity = displayLoading;
          this.backgroundActivityRefresh = refresh;
          // Else, we display a loading spinner
          this.displayLoadingSpinner = !displayLoading;
          let filter = `status != 'Deleted' && clientId=${this.userProfile.clientId}`;
          let page = this.messageOptions.page;
          // We fetch a page ahead. To do this, on page 1 we fetch both page 1 & 2 at the same time. Then we're a page ahead the rest of the time
          let limit = this.activityPageSize; //page == 1 ? 20 : 10;
          let offset = page == 1 || refresh ? 0 : this.messages.length; //(page - 1) * limit;
          let currentContexts = this.computedFilterString;
          console.log({
            filter: filter,
            limit: limit,
            offset: offset
          });

          let since;
          if (refresh && this.messages.length) {
            since = Math.max(...this.messages.map(x => x.messageLogId));
          }
          // Immediately overwrite lastActivityRefresh
          this.lastActivityRefresh = momentTz()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.sssZ");
          const params = {
            since,
            filter: filter,
            limit: limit,
            offset: offset,
            sort: "sendDate DESC",
            recognition_recipient: this.filters.recognition_recipient //&& this.filters.recognition
              ? 1
              : 0
          };
          const messages = await CommunicationService.getMessagesV2(
            params,
            this.computedFilterString,
            this.magicLinkToken
          );
          // Ensures that if there are multiple api requests at once, that we're only setting data for the most recent
          const newRecognitionRecipientValue = this.filters
            .recognition_recipient //&& this.filters.recognition
            ? 1
            : 0;
          if (
            this.computedFilterString !== currentContexts ||
            newRecognitionRecipientValue != params.recognition_recipient
          ) {
            console.log("Ignoring request ", {
              currentContexts,
              displayLoading,
              refresh,
              reset,
              newRecognitionRecipientValue
            });
            return;
          }
          console.log("Messages ", messages.result);
          let newMessages = [];
          const messageArr = messages.result.rows.map(msg => {
            msg.date = momentTz(msg.sendDate)
              .local()
              .format("MMM D - h:mm A");
            // .format("YYYY-MM-DD h:mm a");
            return msg;
          });

          if (reset || !refresh)
            this.messageOptions.count = messages.result.count;

          if (reset) {
            // this.messageOptions.total = messageArr.length;
            this.messages = messageArr;
          } else {
            let numAdded = 0;
            // this.messageOptions.total += messageArr.length;
            messageArr.forEach(item => {
              const existingMsg = this.messages.find(
                x => x.messageLogId == item.messageLogId
              );
              if (!existingMsg) {
                this.messages.push(item);
                newMessages.push(item);
                numAdded++;
              } else console.log("Not adding message already in array");
            });
            // this.messageOptions.total += numAdded;
            if (refresh) this.messageOptions.count += numAdded;
          }
          // this.messageOptions.count = messages.result.count;

          // else this.messageOptions.total += messageArr.length;
          // if (reset == true) {
          //   this.messages = messageArr;
          // } else {
          //   // this.$set(this, "messages", this.messages.concat(messageArr));
          //   this.messages.push(...messageArr);
          // }
          this.loadingActivity = false;
          if (refresh) this.backgroundActivityRefresh = false;
          this.displayLoadingSpinner = false;
          this.refreshingActivity = false;

          this.markActivityAsRead(reset ? messageArr : newMessages);
        } catch (error) {
          console.log("error ", error);
          this.loadingActivity = false;
          if (refresh) this.backgroundActivityRefresh = false;
          this.displayLoadingSpinner = false;
        }
      } else {
        this.messages = [];
      }
    },
    async getPopupMessages(refresh = false) {
      try {
        console.log("getPopupMessages ", { refresh });
        // Here we have some conditions for if we don't refresh quite yet
        // if (
        //   refresh
        //   // && this.currentPopup
        // )
        //   return;
        console.log("Running getPopupMessages");
        // let page = this.messageOptions.page;
        // // We fetch a page ahead. To do this, on page 1 we fetch both page 1 & 2 at the same time. Then we're a page ahead the rest of the time
        // let limit = this.activityPageSize; //page == 1 ? 20 : 10;
        // let offset = page == 1 ? 0 : (page - 1) * limit;
        // let currentContexts = this.computedFilterString;
        // console.log({
        //   // filter: filter,
        //   limit: limit,
        //   offset: offset
        // });

        let since;
        if (refresh && this.popups.length) {
          since = Math.max(...this.popups.map(x => x.messageLogId));
        } else if (refresh && this.maxPopupId) since = this.maxPopupId;
        // if (refresh && this.popups.length) {
        //   since = Math.max(...this.popups.map(x => x.messageLogId));
        // }
        // Immediately overwrite lastPopupRefresh
        this.lastPopupRefresh = momentTz()
          .utc()
          .format("YYYY-MM-DD HH:mm:ss.sssZ");
        const messages = await CommunicationService.getPopupMessages(
          {
            since
            // filter: filter,
            // limit: limit,
            // offset: offset,
            // sort: "sendDate DESC"
          },
          this.magicLinkToken
        );
        // Ensures that if there are multiple api requests at once, that we're only setting data for the most recent
        // if (this.computedFilterString !== currentContexts) {
        //   console.log("Ignoring request ", currentContexts);
        //   return;
        // }
        console.log("Popups ", messages);
        let arr = messages.rows.map(msg => {
          msg.date = momentTz(msg.sendDate)
            .local()
            .format("MMM D - h:mm A");
          return msg;
        });

        if (refresh) {
          arr.forEach(item => {
            const existingPopup = this.popups.find(
              x => x.messageLogId == item.messageLogId
            );
            if (!existingPopup) this.popups.push(item);
          });
        } else this.popups = arr;

        // Update these values if it's not already open
        if (this.popups.length && !this.displayPopup) {
          this.currentPopupIndex = 0;
          this.displayPopup = true;
        }

        // this.messageOptions.count = messages.result.count;
        // if (reset == true) this.messageOptions.total = messageArr.length;
        // else this.messageOptions.total += messageArr.length;
        // if (reset == true) {
        //   this.messages = messageArr;
        // } else {
        //   // this.$set(this, "messages", this.messages.concat(messageArr));
        //   this.messages.push(...messageArr);
        // }
      } catch (error) {
        console.log("error getting popup messages ", error);
      } finally {
        this.refreshTimeout = setTimeout(
          () => {
            console.log("Background refresh running");
            this.getPopupMessages(true);
            this.getActivity(false, false, true);
          },
          this.backgroundRefreshTimeout < 500
            ? 500
            : this.backgroundRefreshTimeout
        );
      }
    },
    async markActivityAsRead(messages = []) {
      try {
        console.log({ messages });
        const readMessages = messages
          .filter(x => !x.readOnDashboard)
          .map(x => x.messageLogId);
        console.log("We need to mark messages as read ", readMessages);
        if (!readMessages.length) return;
        const readResponse = await CommunicationService.updateMessageLogAsReadV2(
          readMessages,
          undefined,
          this.magicLinkToken
        );
        console.log("Marked messages as read ", readResponse);

        // Now refresh the unread count
        this.$store.dispatch("setUnreadMessages");
      } catch (e) {
        console.log("Error marking messages as read ", e);
      }
    },
    filterActivity(type) {
      if (type == "recognition_recipient") {
        this.filters.recognition = false;
      } else if (type == "recognition") {
        this.filters.recognition_recipient = false;
      }
      this.refreshingActivity = true;

      this.getActivity(false, true);
    },
    loadOneFilter(type) {
      Object.keys(this.filters).forEach(context => {
        if (type != context) this.filters[context] = false;
        else this.filters[context] = true;
      });

      this.refreshingActivity = true;
      this.getActivity(false, true);
    },
    loadMoreMessages() {
      this.messageOptions.page = this.messageOptions.page + 1;
      this.getActivity(false, false);
    },
    loadRecommendation(key, admin = false) {
      var option = (admin
        ? this.adminRecommendations
        : this.robinRecommendations
      ).find(x => x.recommendationId == key);
      if (option.context == "wallet" && admin) {
        this.$router.push({
          name: "paymentadmin",
          params: {
            preload: "wizard",
            previousRoute: this.$route.name,
            oneTimePayment: true
          }
        });
      } else if (option.context == "recognition") {
        this.$router.push({
          name: "recognitionwizard",
          params: {
            // preload: "wizard",
            previousRoute: this.$route.name
            // oneTimePayment: true
          }
        });
      } else if (option.context == "learning" && admin) {
        this.$router.push({
          name: "learningbuilder",
          params: { preloadModule: null }
        });
      } else if (option.context == "programs" && admin) {
        this.$router.push({
          name: "programwizard",
          params: { preloadModule: null, sourcePage: "activity" }
        });
      } else if (option.context == "profile") {
        this.$router.push({
          name: "profile",
          params: {}
        });
      } else if (option.context == "wallet_use") {
        this.$router.push({
          name: "wallet",
          params: {}
        });
      } else if (option.context == "mobile-popup") {
        this.$store.state.displayMobilePopup = true;
      } else if (option.context == "people") {
        this.$router.push({
          name: "people",
          params: {
            showNewUserDialogOnLoad: true
          }
        });
      } else if (option.context == "budgets") {
        this.$router.push("/budgets");
      } else if (
        (option.context == "programs" || option.context == "program") &&
        option.contextResourceId
      ) {
        this.$router.push({
          name: "programdetails",
          params: {
            programId: option.contextResourceId.toString(),
            sourcePage: "activity"
          }
        });
      }
    },
    loadRecognition() {
      this.$router.push({
        name: "recognitionwizard",
        params: {
          // preload: "wizard",
          previousRoute: this.$route.name
          // oneTimePayment: true
        }
      });
    },
    // async updateMessageLike(id, val) {
    //   try {
    //     console.log({ val, id });
    //     const index = this.computedVisibleMessages.findIndex(
    //       x => x.messageLogId == id
    //     );
    //     console.log(index);
    //     if (index !== -1) this.computedVisibleMessages[index].likedByUser = val;

    //     await CommunicationService.updateMessageLogV2(id, { likedByUser: val });
    //   } catch (e) {
    //     console.log("error updating message's like status ", e);
    //   }
    // },
    async createMessageComment(obj, popup) {
      console.log("Create message comment", obj);

      try {
        let comment = await CommunicationService.createMessageComment(
          {
            messageId: obj.messageId,
            comment: obj.comment
          },
          this.magicLinkToken
        );
        console.log("Created comment response ", { comment });
        if (obj.callback && comment[0]) obj.callback(comment[0]);
        if (popup) {
          const index = this.messages.findIndex(
            x => x.messageId == obj.messageId
          );
          if (index !== -1) {
            if (!this.messages[index].comments)
              this.messages[index].comments = [];
            this.messages[index].comments.push({
              ...comment[0],
              name: `${this.userProfile.firstName} ${this.userProfile.lastName}`
            });
          }
        }
      } catch (e) {
        console.log("error creating comment ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "createMessageComment";
        }
        obj.callback();
      }
    },
    async removeMessageComment(commentId, messageId, popup) {
      try {
        await CommunicationService.deleteMessageComment(
          commentId,
          this.magicLinkToken
        );
        if (popup) {
          const index = this.messages.findIndex(x => x.messageId == messageId);
          if (index !== -1) {
            const commentIndex = (
              this.messages[index].comments || []
            ).findIndex(x => x.messageCommentId == commentId);
            if (commentIndex !== -1)
              this.messages[index].comments.splice(commentIndex, 1);
          }
        }
      } catch (e) {
        console.log("error deleting comment ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "removeMessageComment";
        }
      }
    },
    async createMessageReaction(messageId, emoji, popup) {
      try {
        await CommunicationService.createMessageReaction(
          {
            messageId,
            reaction: emoji
          },
          this.magicLinkToken
        );
        if (popup) {
          const index = this.messages.findIndex(x => x.messageId == messageId);
          if (index !== -1) {
            let existingEmoji = this.messages[index].reactions.find(
              x => x.emoji == emoji
            );
            if (existingEmoji && !existingEmoji.hasLiked) {
              existingEmoji.count = existingEmoji.count + 1;
              existingEmoji.hasLiked = true;
            } else if (!existingEmoji) {
              this.messages[index].reactions.push({
                emoji,
                count: 1,
                hasLiked: true
              });
            }
          }
        }
      } catch (e) {
        console.log("error creating reaction ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "createMessageReaction";
        }
      }
    },
    async removeMessageReaction(messageId, emoji, popup) {
      try {
        await CommunicationService.removeMessageReaction(
          messageId,
          {
            reaction: emoji
          },
          this.magicLinkToken
        );

        if (popup) {
          const index = this.messages.findIndex(x => x.messageId == messageId);
          if (index !== -1) {
            let existingEmoji = this.messages[index].reactions.find(
              x => x.emoji == emoji
            );
            if (existingEmoji) {
              existingEmoji.count--;
              existingEmoji.hasLiked = false;
            }
          }
        }
      } catch (e) {
        console.log("error removing reaction ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "removeMessageReaction";
        }
      }
    },
    async dismissMessage(
      messageLogId,
      close = false,
      back = false,
      next = false
    ) {
      try {
        console.log("Dismissing message", { messageLogId, close, back, next });
        CommunicationService.updatePopupMessageAsRead(
          messageLogId,
          this.magicLinkToken
        );
        if (close) {
          this.displayPopup = false;
          this.maxPopupId = this.popups.length
            ? Math.max(...this.popups.map(x => x.messageLogId))
            : null;
          this.popups = [];
          this.currentPopupIndex = null;
        } else if (back) this.currentPopupIndex--;
        else if (next) this.currentPopupIndex++;
      } catch (e) {
        console.log("error dismissing ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "dismissMessage";
        }
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "postItArray",
      "permissions",
      "activity",
      "magicLinkToken"
    ]),
    devEnvironment() {
      return !!(
        process.env.VUE_APP_ENVIRONMENT == "dev" ||
        process.env.VUE_APP_ENVIRONMENT == "test"
      );
    },
    computedVisibleMessages() {
      // Used to sort messages and slice based on how many they're viewing at once
      var messages = this.messages;
      messages.sort(function(a, b) {
        return momentTz(a.sendDate).isAfter(momentTz(b.sendDate)) ? -1 : 1;
      });

      messages = messages.filter(
        m =>
          this.filters[m.context] == true ||
          (this.filters.recognition_recipient && m.context == "recognition")
      );

      return messages;
    },
    adminRecommendations() {
      let arr = this.adminOptions.filter(x =>
        this.permissions.includes(x.permission)
      );
      // Only show recognition admin options if they have any others
      // if (arr.length) arr = [this.recognitionAdmin, ...arr];
      return arr.slice(0, 3);
    },
    robinRecommendations() {
      return this.recommendations.slice(0, 3);
      //.concat(this.recognitionAdmin);
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    timeOfDay() {
      const [hour, am_pm] = momentTz()
        .format("h,A")
        .split(",");

      if (am_pm === "AM") return `Morning`;
      else if (hour == 12 || hour < 6) return `Afternoon`;
      else return `Evening`;
    },
    showMoreButton() {
      // console.log("Current count ", this.messageOptions.count);
      // console.log("Current total ", this.messageOptions.total);
      return (
        this.messageOptions.count > this.computedVisibleMessages.length &&
        (this.filters.program ||
          this.filters.news ||
          this.filters.general ||
          this.filters.wallet ||
          this.filters.recognition ||
          this.filters.recognition_recipient ||
          this.filters.alerts)
      );
    },
    computedFilterString() {
      let string = [];
      if (this.filters.program == true && this.displayProgramFilterButton)
        string.push("program");
      // if (this.filters.survey == true && this.displayProgramFilterButton)
      // string.push("surveys");
      if (this.filters.wallet == true && this.displayWalletFilterButton)
        string.push("wallet");
      if (this.filters.recognition == true) string.push("recognition");
      if (this.filters.recognition_recipient == true)
        string.push("recognition_recipient");
      if (this.filters.news == true) string.push("news");
      if (this.filters.general == true) string.push("general");
      if (this.filters.alerts == true) string.push("alerts");

      if (string.length > 0) return string.join();
      else return null;
    },
    displayWalletFilterButton() {
      if (this.userProfile && this.userProfile.Client)
        return this.userProfile.Client.displayWalletPage;
      return true;
    },
    displayProgramFilterButton() {
      if (this.userProfile && this.userProfile.Client)
        return this.userProfile.Client.displayProgramPage;
      return true;
    },
    currentPopup() {
      if (this.currentPopupIndex == null) return null;
      return this.popups[this.currentPopupIndex];
    }
  }
};
</script>

<style scoped>
.button-text {
  font-size: 20px;
}
.card-column {
  min-width: 290px;
  width: 33%;
  max-width: 350px;
}
.brand-background {
  background-color: var(--v-brand-base);
}
.brand-dark-green-background {
  background-color: var(--v-brandDarkGreen-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.brand-yellow-background {
  background-color: var(--v-brandYellow-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.medium-grey-background {
  background-color: var(--v-mediumGrey-base);
}
.border-radius {
  border-radius: 5px;
}

/* making cards full width mobile & tablets */
@media only screen and (max-width: 800px) {
  .card-column {
    width: 100%;
    max-width: 100%;
  }
}

.outlined-bottom {
  border-bottom: 1px solid;
  /* border-width: 1px 1px 0px 0px; */
  border-color: var(--v-grey-base);
}

.outlined-top {
  border-top: 1px solid;
  /* border-width: 1px 1px 0px 0px; */
  border-color: var(--v-grey-base);
}

.whistle-gradient {
  background: rgb(161, 215, 110);
  background: linear-gradient(0deg, white 5%, #09acec 60%, #065c9e 100%);
}

.admin-button {
  color: #09acec;
}

.full-card-width {
  width: calc(100% - 32px);
}

.progress-bar {
  width: calc(100% - 40px);
}

.progress-bar-bump {
  margin-top: 9px;
}

.robin-recommendation {
  border: solid 1px;
  border-color: lightgray;
}

.robin-icon {
  /* min-width: 35px;
  max-width: 35px; */
  min-width: 40px;
  max-width: 40px;
  padding: 8px 0px 8px 0px;
}

.card-stack {
  /* box-shadow: 10px 5px 2px white; */
  border-radius: 3px;
  box-shadow:
    /* The top layer shadow */ 2px -2px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 8px -6px 0 0 #eee,
    /* The second layer shadow */ 10px -8px 1px rgba(0, 0, 0, 0.15) !important;
  /* Padding for demo purposes */
  /* padding: 30px; */
}

.card-stack-multiple {
  /* box-shadow: 10px 5px 2px white; */
  border-radius: 3px;
  box-shadow:
    /* The top layer shadow */ 2px -2px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 8px -6px 0 0 #eee,
    /* The second layer shadow */ 10px -8px 1px rgba(0, 0, 0, 0.15),
    /* The third layer */ 16px -12px 0 0 #eee !important;
  /* Padding for demo purposes */
  /* padding: 30px; */
}

/* elevation-3
 box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)

    */

/* .height-100 {
  height: 100%;
} */

.recognition-recommendation {
  font-size: large;
  font-weight: bold;
}

.recognition-emoji {
  font-size: 35px;
}

.recognition-emoji-inner-circle {
  background: var(--v-brandCyan-base);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recognition-emoji-outer-circle {
  background: var(--v-brandLightCyan-base);
  border-radius: 50%;
  width: 61px;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19) !important;
}
.message-feed {
  min-height: calc(90vh);
  max-height: calc(100vh - 95px);
  overflow-y: scroll;
  overflow-anchor: none;
}

/* ::-webkit-scrollbar-track {
  background: orange;
} */
/*
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #393812;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
} */

::-webkit-scrollbar {
  /* Height adjusts as the page grows longer */
  height: auto;
  width: 10px;
}
::-webkit-scrollbar-track {
  /* Makes track a dark grey but opaque */
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-corner {
  background: #000;
}

::-webkit-scrollbar-thumb {
  /* Grabber is white, rounded, with a slight shadow */
  background: white;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.robin-recommendation:hover {
  background-color: #eeeeee;
}
</style>
<!-- 

     /* The top layer shadow */ 0 1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 0 10px 0 -5px #eee,
    /* The second layer shadow */ 0 10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */ 0 20px 0 -10px #eee,
    /* The third layer shadow */ 0 20px 1px -9px rgba(0, 0, 0, 0.15) !important;
 -->
