<template>
  <div class="full-height">
    <div
      width="100%"
      rounded="0"
      class="full-height"
      id="payment-manager"
      :class="!isMobile ? 'd-flex flex-column' : ''"
    >
      <!-- Header card -->
      <v-card
        class="d-flex mt-3 py-4"
        :class="{
          'flex-column': $vuetify.breakpoint.xs,
          'mx-6 mb-4': !isMobile,
          'mx-1': isMobile
        }"
      >
        <v-col
          :cols="
            $vuetify.breakpoint.xs ? '12' : $vuetify.breakpoint.sm ? '6' : '5'
          "
          class="d-flex pa-0"
        >
          <v-icon
            :large="!isMobile"
            dark
            class="pa-2 ml-3 mr-4 my-2 brand-dark-green-background border-radius icon"
            >mdi-wallet-plus-outline</v-icon
          >
          <p
            class="font-weight-black word-break dark-green-text mb-auto mt-auto bold-font page-title"
          >
            Rewards Management
          </p>
        </v-col>
        <v-col v-if="!isMobile"></v-col>
        <v-col
          :cols="
            $vuetify.breakpoint.xs ? '12' : $vuetify.breakpoint.sm ? '6' : '4'
          "
          :class="$vuetify.breakpoint.xs ? 'px-0' : 'px-12'"
          :style="{ height: 'fit-content' }"
        >
          <v-autocomplete
            outlined
            dense
            class="my-auto pa-1 full-width"
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            v-model="clientId"
            @change="refreshClientId"
            v-if="userProfile.clientId === 1"
          >
          </v-autocomplete>
        </v-col>
      </v-card>
      <!-- <v-divider class="mx-4" /> -->
      <v-row
        class="d-flex justify-space-between flex-grow-0"
        :style="{ 'min-height': '175px', height: 'fit-content' }"
        :class="{
          'flex-column mt-2': isMobile,
          'px-6': !isMobile
        }"
        v-if="visibleMessages.length > 0"
      >
        <v-col
          :cols="isMobile ? '12' : '4'"
          v-for="(message, index) in visibleMessages"
          :key="index"
          class="card-col"
        >
          <v-card
            :class="{ 'mx-1': isMobile }"
            :style="{ height: 'fit-content', 'min-height': '100%' }"
            @click="loadRecommendation(message)"
          >
            <div class="pa-3 d-flex flex-column justify-center">
              <div
                class="d-flex justify-space-between align-center pt-4 pb-2 mb-2 word-break card-header"
              >
                <div class="d-flex align-center pr-2">
                  <v-icon
                    large
                    color="white"
                    class="pa-2 rounded mr-4 brand-dark-green-background icon"
                    :class="message.backgroundColor"
                    >{{ message.icon }}</v-icon
                  >
                  <!-- <p
                    style="line-height: 22px; font-size: 1.1em;"
                    class="my-auto"
                  >
                    Send a
                    <span class="font-weight-black">one time payment</span>
                  </p> -->
                  <span v-html="message.headline"></span>
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </div>
              <!-- <p class="mt-6 mb-0">
                Send a single payment to one or many people with the option to
                restrict funds for a specific purpose
              </p> -->
              <span v-html="message.body" class="mb-2 text-left"></span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="d-flex justify-space-between flex-grow-1"
        :style="isMobile ? {} : { 'max-height': '60%' }"
        :class="{
          'flex-column': isMobile,
          'px-6': !isMobile
        }"
      >
        <v-col :cols="isMobile ? '12' : '4'" class="card-col">
          <v-card
            class="text-left d-flex flex-column justify-start py-2"
            :class="isMobile ? 'mb-3 mx-1' : 'mb-10'"
            :style="{ 'min-height': '100%' }"
          >
            <h4 class="font-weight-black pt-2">Manage:</h4>
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="activeView === 3 ? 'highlighted-button' : ''"
              @click="handleViewChange(3)"
            >
              Popular rewards
              <v-icon large color="grey">{{
                isMobile ? "mdi-chevron-down" : "mdi-chevron-right"
              }}</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="activeView === 1 ? 'highlighted-button' : ''"
              @click="handleViewChange(1)"
            >
              Admin budgets
              <v-icon large color="grey">{{
                isMobile ? "mdi-chevron-down" : "mdi-chevron-right"
              }}</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="activeView === 2 ? 'highlighted-button' : ''"
              @click="handleViewChange(2)"
            >
              Future payments
              <v-icon color="grey" large>{{
                isMobile ? "mdi-chevron-down" : "mdi-chevron-right"
              }}</v-icon>
            </v-btn>
            <v-divider class="mx-1 mb-6" />
            <h4 class="font-weight-black pt-2">Other actions</h4>
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadPayoutForm"
              v-if="displayCSVPaymentWizard"
              >Process payout files</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="slackDialog = true"
              >Add Slack integration</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadPaymentsConsole"
              v-if="permissions.includes('wallets:create:cashpayment')"
              >Advanced payments</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="showPaymentAccountSetupDialog"
              v-if="displayKYBButton"
              >Complete your business verification</v-btn
            >
          </v-card>
        </v-col>
        <v-col :cols="isMobile ? '12' : '8'" class="card-col">
          <v-card
            class="text-left d-flex flex-column justify-space-between"
            :style="{ 'min-height': '100%' }"
            :class="{
              'full-width': isMobile,
              'py-3': activeView != 3,
              'px-0': activeView == 3
            }"
            :color="activeView == 3 ? 'transparent' : 'white'"
            :elevation="activeView == 3 ? 0 : undefined"
          >
            <v-col cols="12" v-if="activeView == 0">
              <div
                v-if="loadingBudgets && visibleBudgets.length == 0"
                class="text-center mt-6"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <p class="mt-4">Loading your account details...</p>
              </div>
              <div v-else-if="accountBalance" class="align-text-center">
                <div class="d-flex full-width justify-space-between px-4">
                  <p>Account balance</p>
                  <p>
                    $
                    {{
                      formatNum(
                        truncateNum(accountBalance.totalAccountBalance, 2)
                      )
                    }}
                  </p>
                </div>
                <div class="d-flex full-width justify-space-between px-4">
                  <p>Allocated</p>
                  <p>
                    $
                    {{
                      formatNum(truncateNum(accountBalance.allocatedBalance, 2))
                    }}
                  </p>
                </div>
                <v-divider class="mx-1 mb-4" />
                <div
                  class="d-flex full-width justify-space-between px-4 font-weight-black"
                >
                  <p>Available</p>
                  <p>
                    $
                    {{
                      formatNum(
                        truncateNum(accountBalance.formattedBudgetBalance, 2)
                      )
                    }}
                  </p>
                </div>
                <div class="d-flex full-width justify-space-between px-4">
                  <p>Pending incoming transfers</p>
                  <p>
                    $
                    {{ formatNum(truncateNum(pendingTransfersTotal, 2)) }}
                  </p>
                </div>
                <v-btn
                  color="brandCyan"
                  depressed
                  class="white--text mx-auto mt-4"
                  :style="{ 'border-radius': '50px' }"
                  @click="
                    fundBudget(null, accountBalance, null, false, null, null)
                  "
                  :loading="loadingBudgets"
                  v-if="
                    (permissions.includes('wallets:fund:budget') &&
                      accountBalance.permissions &&
                      accountBalance.permissions.owner) ||
                      (permissions.includes('wallets:manualfund:budget') &&
                        userProfile.clientId == 1)
                  "
                >
                  Add Funds
                </v-btn>
              </div>
              <div
                class="d-flex flex-column align-items-center text-center"
                v-else
              >
                <Robin
                  :showText="false"
                  :width="160"
                  :height="160"
                  animation="hello"
                  :loop="true"
                  class="mt-2 mr-6 justify-center"
                />
                <p class="mt-6">
                  It looks like you haven't funded your account. Start sending
                  money by funding your account!
                </p>
                <v-btn
                  color="brandCyan"
                  depressed
                  class="white--text mx-auto mt-4"
                  :style="{ 'border-radius': '50px' }"
                  @click="routeToBudgets"
                  v-if="permissions.includes('vue:read:budgets')"
                >
                  Manage Budgets
                </v-btn>
              </div>
            </v-col>
            <!-- Budgets -->
            <v-col cols="12" v-if="activeView == 1">
              <div
                v-if="loadingBudgets && visibleBudgets.length == 0"
                class="text-center mt-6"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <p class="mt-4">Loading your admin budgets...</p>
              </div>
              <div v-else-if="visibleBudgets.length > 0">
                <div class="d-flex justify-space-between align-items-center">
                  <v-btn
                    color="primary"
                    text
                    depressed
                    class="white--text mr-auto mb-auto"
                    :style="{ 'border-radius': '50px' }"
                    @click="routeToBudgets"
                    v-if="permissions.includes('vue:read:budgets')"
                  >
                    Manage Budgets
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-text-field
                    outlined
                    placeholder="Search"
                    clearable
                    single-line
                    dense
                    hide-details
                    v-model="budgetTableSearch"
                    :style="
                      $vuetify.breakpoint.xs ? {} : { 'max-width': '45%' }
                    "
                    class="mb-2 ml-auto"
                  ></v-text-field>
                </div>
                <v-data-table
                  :headers="budgetColumns()"
                  :items="visibleBudgets"
                  item-key="budgetId"
                  :items-per-page="10"
                  :search="budgetTableSearch"
                  :loading="loadingBudgets"
                  loading-text="Loading... Please wait"
                  no-data-text="No budgets found"
                  no-results-text="No budgets found in search"
                  @click:row="loadEditBudgetForm"
                  :class="
                    permissions.includes('budgets:create:budget')
                      ? 'pointer-rows'
                      : ''
                  "
                >
                  <template v-slot:[`item.budgetBalance`]="{ item }">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.xs ? 'flex-column' : ''"
                    >
                      <span
                        class="d-flex align-center"
                        :class="
                          $vuetify.breakpoint.xs ? 'ml-auto' : 'justify-left'
                        "
                        >{{
                          item.formattedBudgetBalance !== undefined &&
                          item.formattedBudgetBalance !== null
                            ? (item.awardId == 1 ? "$ " : "") +
                              formatNum(
                                parseFloat(item.formattedBudgetBalance).toFixed(
                                  item.awardId == 1 ? 2 : 2
                                )
                              ) +
                              (item.awardId == 1
                                ? ""
                                : item.budgetBalance == 1
                                ? " " + item.Award.unitSingular
                                : " " + item.Award.unitPlural)
                            : formatNum(item.budgetBalance)
                        }}</span
                      >
                      <v-btn
                        v-if="
                          (item.permissions && item.permissions.owner) ||
                            (permissions.includes(
                              'wallets:manualfund:budget'
                            ) &&
                              userProfile.clientId == 1)
                        "
                        color="brandCyan"
                        dark
                        small
                        depressed
                        :loading="budgetFundingLoading === item.budgetId"
                        @click.native.stop="
                          fundBudget(null, item, null, false, null, null)
                        "
                        :class="$vuetify.breakpoint.xs ? 'my-2' : ''"
                      >
                        Add Funds
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>
              <div
                v-else
                class="d-flex flex-column align-items-center text-center"
              >
                <Robin
                  :showText="false"
                  :width="160"
                  :height="160"
                  animation="hello"
                  :loop="true"
                  class="mt-2 mr-6 justify-center"
                />
                <p class="mt-6">
                  It looks like you don't have any budgets.
                </p>
                <v-btn
                  depressed
                  color="brandCyan"
                  class="white--text mx-auto mt-4"
                  :style="{ 'border-radius': '50px' }"
                  @click="loadBudgetBuilder(null, false)"
                  v-if="permissions.includes('budgets:create:budget')"
                >
                  Create budget
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-if="activeView == 2">
              <v-text-field
                outlined
                placeholder="Search"
                clearable
                single-line
                dense
                hide-details
                v-model="paymentRuleTable.search"
                :style="$vuetify.breakpoint.xs ? {} : { width: '45%' }"
                class="mb-2 ml-auto"
              ></v-text-field>
              <v-data-table
                :headers="paymentRuleHeaders"
                :items="paymentRuleTable.paymentRules"
                item-key="paymentRuleId"
                :items-per-page="paymentRuleTable.itemsPerPage"
                :loading="paymentRuleTable.loading"
                :search="paymentRuleTable.search"
                loading-text="Loading... Please wait"
                no-data-text="No future payments found"
                no-results-text="No future payments found in search"
                :footer-props="paymentRuleTable.footerProps"
              >
                <!-- <template v-slot:[`item.frequency`]="{ item }">
                  <span
                    :style="{ 'text-transform': 'capitalize' }"
                    >{{ item.frequency.toLowerCase() }}</span
                  >
                </template> -->
              </v-data-table>
            </v-col>
            <v-col cols="12" v-if="activeView == 3" class="pa-0">
              <RewardsLibrary @load-action="loadRecommendation" />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <!-- DIALOGS SECTION -->
      <v-dialog
        fullscreen
        v-model="payoutFileDialog"
        transition="dialog-bottom-transition"
        class="z-index-front"
      >
        <v-card>
          <PaymentCSVUpload
            v-if="payoutFileDialog"
            :budgets="budgets"
            :loadingBudgets="loadingBudgets"
            @reset="payoutFileDialog = false"
            @get-budgets="getBudgets"
            @payments-console="loadPaymentsConsole"
          ></PaymentCSVUpload>
        </v-card>
      </v-dialog>

      <!-- Slack modal -->
      <v-dialog v-model="slackDialog" width="500">
        <v-card
          class="d-flex flex-column align-start full-width pa-5 text-center"
          rounded="0"
          elevation="3"
          width="100%"
        >
          <div class="full-width text-center">
            <p class="text-h5 word-break mb-5">
              Interested in adding Whistle payments to Slack?
            </p>
            <p class="mb-2">
              Contact us to get started!
            </p>
            <p class="full-width mb-1">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="full-width">Phone (Toll Free) - <b>(855) 264-3329</b></p>

            <div class="d-flex justify-center my-5">
              <v-btn
                depressed
                rounded
                color="brandCyan"
                class="white--text"
                @click="slackDialog = false"
                ><span>Close</span></v-btn
              >
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog
        fullscreen
        v-model="dialogKYB"
        transition="dialog-bottom-transition"
        class="z-index-front"
      >
        <v-card>
          <MarqetaBusinessCreation
            v-if="dialogKYB"
            @reset="resetKYB"
          ></MarqetaBusinessCreation>
        </v-card>
      </v-dialog>

      <!-- Displays loading message while submitting payout file -->
      <v-dialog v-model="submittingPayoutFile" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Submitting payout file
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text> </v-card
      ></v-dialog>
    </div>
    <!-- Dialog for asking if they want to set up KYB -->
    <v-dialog
      v-if="paymentAccountSetup.dialog"
      v-model="paymentAccountSetup.dialog"
      width="500"
    >
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center">
          <v-card-title
            class="word-break text-left"
            v-if="paymentAccountSetup.error"
          >
            Looks like there was a problem
          </v-card-title>
          <v-card-title
            class="word-break text-left"
            v-else-if="paymentAccountSetup.success"
          >
            Whistle Payments has been activated!
          </v-card-title>
          <v-card-title class="word-break text-left" v-else>
            Activate Whistle Payments
          </v-card-title>
        </div>
        <transition name="topic-left" mode="out-in">
          <p
            class="py-2 mx-4 text-left"
            key="0"
            v-if="
              paymentAccountSetup.success != true &&
                paymentAccountSetup.error != true
            "
          >
            Before starting the full registration process, Whistle allows you to
            process payments for free with a total limit of $10,000.
            <br /><br />
            To continue using Whistle Payments after that, business registration
            is required.
          </p>
          <!-- Success message -->
          <div
            v-else-if="paymentAccountSetup.success == true"
            class="mx-2 pb-2 d-flex flex-column align-center"
            key="1"
          >
            <Robin
              animation="ohYeah"
              :width="150"
              class="mx-auto robin-animation"
              :loop="true"
            />
            Awesome! Your account is set up with a limit of $10,000.
          </div>
          <!-- Error message -->
          <div
            v-else-if="paymentAccountSetup.error == true"
            class="mx-2 pb-2 pt-2 pl-3"
            key="2"
          >
            <p class="full-width align-text-left">
              If the problem continues, feel free to reach out to customer
              service via one of the following methods...
            </p>
            <p class="align-text-left full-width">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="align-text-left full-width">
              Phone (Toll Free) - <b>(855) 264-3329</b>
            </p>
          </div>
        </transition>
        <!-- Buttons -->
        <div>
          <v-card-actions
            class=""
            v-if="
              paymentAccountSetup.error != true &&
                paymentAccountSetup.success != true
            "
          >
            <v-btn
              color="primary"
              outlined
              width="210"
              class="mr-4"
              depressed
              :disabled="paymentAccountSetup.loading"
              @click="loadKYB"
              >Go to registration</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              width="210"
              depressed
              @click="setupPaymentAccount"
              :loading="paymentAccountSetup.loading"
              >Activate now</v-btn
            >
          </v-card-actions>
          <v-card-actions class="mx-8" v-else>
            <v-btn
              color="primary"
              width="170"
              class="mx-auto"
              @click="resetPaymentAccountSetupDialog"
              >Done</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <Plaid v-if="loadPlaid" :hideButton="true" />
    <!-- More dialogs -->
    <!-- <v-dialog
      fullscreen
      persistent
      v-model="dialogPaymentsWizard"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <PaymentsWizardV3
          v-if="dialogPaymentsWizard"
          ref="paymentsWizard"
          :budgets="budgets"
          :loadingBudgets="loadingBudgets"
          :oneTimePayment="sendOneTimePayment"
          @reset="resetPaymentsWizard"
          @update-client="refreshClientId"
          @payments-console="loadPaymentsConsole"
        ></PaymentsWizardV3>
      </v-card>
    </v-dialog> -->
    <v-dialog
      fullscreen
      v-model="dialogPaymentsConsole"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <PaymentsConsole
          v-if="dialogPaymentsConsole"
          :budgets="budgets"
          @reset="resetPaymentsConsole"
          @get-budgets="getBudgets"
        ></PaymentsConsole>
      </v-card>
    </v-dialog>
    <!-- Nav drawers -->
    <v-navigation-drawer
      v-model="showBudgetDialog"
      temporary
      fixed
      right
      width="500"
    >
      <BudgetCreatorWidget
        v-if="showBudgetDialog"
        @close="showBudgetDialog = false"
        @get-budgets="getBudgets"
        @getPartners="getAwardFulfillmentPartners"
        :showBudgetWarning="false"
        :editingBudget="budgetForm.editing"
        :editBudgetObject="budget"
        :preloadParentBudget="preloadParentBudget"
        :preloadPanel="preloadBudgetPanel"
        :budgets="budgets"
        :clientId="clientId"
        source="PAYMENT_ADMIN"
      />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="dialogPaymentMethods"
      temporary
      fixed
      right
      width="500"
      disable-route-watcher
    >
      <StripePaymentMethodsManager
        v-if="dialogPaymentMethods"
        @reset="dialogPaymentMethods = false"
        @get-budgets="getBudgets"
        @get-funding-limit="getBudgetFundingStatuses"
        @load-budget-activity="loadBudgetActivity()"
        :destinationBudget="destinationBudgetForFunding"
        :sourceBudget="sourceBudgetForFunding"
        :budgets="budgets"
        :clientId="clientId"
        :preloadMenu="preloadPaymentMenu"
        :requestFunds="budgetFundingRequest"
        :preloadAmount="preloadFundingAmount"
        :preloadBudgetActivityId="preloadBudgetActivityId"
        :fundingLimit="clientFundingStatus"
        source="BUDGETS"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
import WalletService from "@/services/WalletService";
import BudgetService from "@/services/BudgetService";
import MarqetaService from "@/services/MarqetaService";

// import MarqetaBusinessCreation from "@/components/MarqetaBusinessCreation.vue";
import PaymentsConsole from "@/components/PaymentsConsole.vue";
import RewardsLibrary from "@/components/rewards-admin/RewardsLibrary.vue";
// // import PaymentsWizard from "@/components/PaymentsWizard.vue";
// import PaymentsWizardV3 from "@/components/PaymentsWizardV3.vue";
import BudgetCreatorWidget from "@/components/BudgetCreatorWidget.vue";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";
// import PaymentsManager from "@/components/PaymentsManager.vue";
import Robin from "@/components/Robin";
import Plaid from "@/components/Plaid";
import PaymentCSVUpload from "@/components/PaymentCSVUpload.vue";
import MarqetaBusinessCreation from "@/components/MarqetaBusinessCreation.vue";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "PaymentAdminV3",
  title: "Whistle | Rewards Management",
  components: {
    // MarqetaBusinessCreation,
    PaymentsConsole,
    RewardsLibrary,
    // // PaymentsWizard,
    // PaymentsManager,
    Robin,
    Plaid,
    // PaymentsWizardV3,
    BudgetCreatorWidget,
    StripePaymentMethodsManager,
    PaymentCSVUpload,
    MarqetaBusinessCreation
  },
  props: {
    preload: {
      type: String,
      defualt: null
    },
    // Used if we came from somewhere else and immediately loaded the payments console
    previousRoute: {
      type: String,
      default: null
    },
    previousRouteProps: {
      type: Object,
      default: null
    },
    oneTimePayment: Boolean
  },
  data() {
    return {
      // image: null,
      dialogKYB: false,
      dialogPaymentsConsole: false,
      dialogPaymentsWizard: false,
      // dialogExitMessage: false,
      // dialogProgramLibrary: false,
      // dialogMetricDefinition: false,
      // dialogProgramAttachment: false,
      // dialogUploadMetrics: false,
      // dialogManagePayments: false,
      // viewExistingPrograms: false,
      // viewActivePrograms: false,
      // viewProgramLibrary: false,
      // viewQualifyingRules: false,
      // viewAwardRules: false,
      // viewContent: false,
      // programs: [],
      // groups: [],
      budgets: [],
      // programBudgets: [],
      // metrics: [],
      // content: [],
      // qualifierRules: [],
      // awardRules: [],
      // parentPaymentRules: [],
      // loadingQualifiers: false,
      // loadingAwardRules: false,
      // loadingContent: false,
      loadingBudgets: false,
      payoutFileDialog: false,
      payoutFileErrorDialog: false,
      payoutFileSuccessDialog: false,
      submittingPayoutFile: false,
      payoutDescription: "",
      payoutFileErrorMsg: "",
      payoutFileNoMatch: [],
      e6: 1,
      payoutFile: null,
      selectedBudget: null,
      // viewTable: {
      //   columns: [],
      //   visiblePrograms: [],
      //   loading: true,
      //   search: null,
      //   searchPlaceholderText: null,
      // },
      // createContentWidget: false,
      // displayLoadingIconOnProgram: null,
      loadPlaid: false,
      fundingArray: [],
      budgetFundingLoading: false,
      paymentAccountSetup: {
        dialog: false,
        success: false,
        error: false,
        loading: false
      },
      sendOneTimePayment: false,
      messages: [
        // {
        //   headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
        //       Complete your
        //       <span class="font-weight-black">business verification</span>
        //     </h5>`,
        //   body: `<p class="mb-0">
        //       Whistle's banking partners require a business verification
        //         process to be completed to add more than $10,000 to your budgets. Your Whistle CSM can help you through this process, as well as answer any questions you have.
        //     </p>`,
        //   icon: "mdi-storefront-check",
        //   backgroundColor: "brand-dark-green-background",
        //   action: "kyb",
        //   permission: "marqeta:create:business"
        // },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Send a
              <span class="font-weight-black">single reward</span> now
            </h5>`,
          body: `<p class="mb-0">
              Send a reward to team member immediately
            </p>`,
          icon: "mdi-account-heart",
          backgroundColor: "brand-dark-green-background",
          action: "one-time-payment",
          permission: "wallets:create:cashpayment"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Send a
              <span class="font-weight-black">group reward</span> now
            </h5>`,
          body: `<p class="mb-0">
              Send a reward to a group of people immediately
            </p>`,
          icon: "mdi-account-multiple",
          backgroundColor: "brand-dark-green-background",
          action: "one-time-payment",
          permission: "wallets:create:cashpayment"
        },
        // {
        //   headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto  rec-header">
        //       Create an
        //       <span class="font-weight-black">admin budget</span>
        //       to support your programs
        //     </h5>`,
        //   body: `<p class="mb-0">
        //       Admin budgets are used to control how funds are managed through
        //       your programs
        //     </p>`,
        //   icon: "mdi-wallet",
        //   backgroundColor: "brand-dark-green-background",
        //   action: "admin-budget",
        //   permission: "budgets:create:budget"
        // },

        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Set up a
              <span class="font-weight-black">recurring reward</span>
            </h5>`,
          body: `<p class="mb-0">
              Set up an automatic reward to a group of people on a recurring basis
            </p>`,
          icon: "mdi-wallet",
          backgroundColor: "brand-dark-green-background",
          action: "recurring-payment",
          permission: "wallets:create:cashpayment"
        }
        // {
        //   headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
        //       Send
        //       <span class="font-weight-black">bulk payments</span>
        //       from a flat file
        //     </h5>`,
        //   body: `<p class="mb-0">
        //       Pay lots of people at once. Great for running your own competitions,
        //       or processing channel incentive payments
        //     </p>`,
        //   icon: "mdi-cog",
        //   backgroundColor: "brand-dark-green-background",
        //   action: "csv",
        //   permission: "wallets:create:cashpayment"
        // },
        // {
        //   headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
        //       Create an
        //       <span class="font-weight-black" >employee budget program</span >
        //       for a specific purpose </h5>`,
        //   body: `<p class="mb-0">
        //       Build more inclusive and empowered teams by setting up culture,
        //       philanthropy or other employee budgets
        //     </p>`,
        //   icon: "mdi-star",
        //   backgroundColor: "brand-background",
        //   action: "budget-program",
        //   permission: "wallets:create:cashpayment"
        // }
      ],
      showBudgetDialog: false,
      activeView: 3,
      dialogPaymentMethods: false,
      sourceBudgetForFunding: null,
      destinationBudgetForFunding: null,
      preloadPaymentMenu: null,
      budgetFundingRequest: null,
      preloadFundingAmount: null,
      preloadBudgetActivityId: null,
      budgetTableSearch: null,
      budgetForm: {
        menuStartDate: false,
        menuEndDate: false,
        endDateDisabled: false,
        endDateCheckBox: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      slackDialog: false,
      paymentRuleTable: {
        itemsPerPage: 10,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        paymentRules: [],
        nextPage: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, -1]
        },
        latestFilter: null,
        latestSort: null
      },
      paymentRuleHeaders: [
        { text: "Name", value: "User.displayName", width: "33%" },
        { text: "Payment Date", value: "formattedStartDate" },
        { text: "Frequency", value: "frequency", width: "13%" },
        {
          text: "Memo",
          value: "ParentPaymentRule.description",
          width: "33%"
        }
      ]
    };
  },
  created() {
    // this.clientId = this.userProfile.clientId;
    this.getBudgets(true);
    this.getBudgetFundingStatuses();
    // if (this.permissions.includes("paymentrules:read:paymentrule"))
    //   this.getPaymentRules();
    if (this.preload == "payment") {
      // Most likely coming from the wallet so we open the payments console
      this.loadPaymentsConsole();
    } else if (
      this.preload == "wizard" ||
      this.$route.name == "paymentwizard"
    ) {
      if (this.oneTimePayment == true) {
        this.sendOneTimePayment = true;
      }
      this.loadPaymentsWizard();
    } else if (
      this.preload &&
      this.preload == "kyb" &&
      this.permissions.includes("marqeta:create:business")
    ) {
      this.loadKYB();
    }

    let query = Object.assign({}, this.$route.query);
    console.log(query);
    console.log(this.type);
    if (query.oauth_state_id) {
      this.loadPlaid = true;
      // this.loadPaymentsWizard();
    }
    this.getScheduledPayments(0, true);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    // uploadPayoutFile() {
    //   this.submittingPayoutFile = true;
    //   WalletService.uploadPayoutFile(
    //     this.payoutFile,
    //     this.selectedBudget,
    //     this.payoutDescription
    //   )
    //     .then(response => {
    //       this.submittingPayoutFile = false;
    //       console.log("Payout upload repsonse: ", response);
    //       if (response.error !== undefined) {
    //         //There was an error
    //         if (response.error.message !== undefined) {
    //           this.payoutFileErrorMsg = response.error.message;
    //         } else {
    //           this.payoutFileErrorMsg = response.error;
    //         }
    //         this.payoutFileErrorDialog = true;
    //       } else {
    //         //Sucessfully paid out to all users
    //         console.log(response.result);
    //         this.payoutFileNoMatch = response.result.noMatchEmails;
    //         this.payoutFileSuccessDialog = true;
    //         //this.payoutFileDialog = false;
    //       }
    //     })
    //     .catch(error => {
    //       this.submittingPayoutFile = false;
    //       console.log("Error! ", error);
    //       this.payoutFileErrorMsg = error;
    //       this.payoutFileErrorDialog = true;
    //     });
    // },
    downloadTemplate() {
      WalletService.downloadTemplate();
    },
    routeToBudgets() {
      this.payoutFile = null;
      this.selectedBudget = null;
      this.payoutFileDialog = false;
      this.$router.push({ name: "budgets" });
    },
    loadPayoutForm() {
      this.payoutFileDialog = true;
      this.payoutFile = null;
      this.payoutFileErrorMsg = "";
      this.payoutFileErrorDialog = false;
      this.payoutFileNoMatch = [];
      this.payoutFileSuccessDialog = false;
      this.selectedBudget = null;
      this.payoutDescription = null;
    },
    getBudgets(autoChangeTab = false) {
      this.loadingBudgets = true;
      let clientId = this.clientId;
      BudgetService.getBudgetsAdminView(
        {
          clientId,
          // With magic links, we can only open the payment wizard so we only need lite data and cash budgets
          lite: 1,
          //this.magicLinkToken && this.$route.name == "paymentwizard" ? 1 : 0,
          awardId:
            this.magicLinkToken && this.$route.name == "paymentwizard"
              ? 1
              : undefined
        },
        this.magicLinkToken
      )
        .then(response => {
          console.log("Budget response payment admin: ", response);

          if (clientId != this.clientId)
            return console.log("Returning early due to clientId change");
          //Prune the returned budgets for the data we need for the table
          response = response.filter(
            x =>
              x.status !== "Deleted" &&
              x.permissions &&
              (x.permissions.owner || x.permissions.admin)
          );
          response.forEach(function(budget) {
            // var formattedStartDate = null;
            // var formattedEndDate = null;
            // if (budget.startDate) {
            //   formattedStartDate = moment(
            //     budget.startDate,
            //     "YYYY-MM-DD hh:mm:ss"
            //   ).format("MMM Do YYYY");
            //   console.log(formattedStartDate);
            // }
            // if (budget.endDate) {
            //   formattedEndDate = moment(
            //     budget.endDate,
            //     "YYYY-MM-DD hh:mm:ss"
            //   ).format("MMM Do YYYY");
            // }
            budget.displayName = budget.budgetDisplayName || budget.budgetName;
            // budget.startDate = formattedStartDate;
            // budget.endDate = formattedEndDate;

            budget.formattedBudgetBalance = truncateNumber(
              budget.budgetBalance,
              2
            );

            budget.budgetBalance = truncateNumber(
              parseFloat(budget.budgetBalance),
              2
            );
            budget.displayNameWithBalance =
              (budget.budgetDisplayName || budget.budgetName) +
              (budget.awardId == 1 ? " - $" : " - ") +
              numberWithCommas(budget.budgetBalance);
          });
          this.budgets = response;
          this.loadingBudgets = false;

          this.$nextTick(() => {
            if (autoChangeTab && !this.accountBalance && this.activeView == 0)
              this.handleViewChange(1);
          });
        })
        .catch(error => {
          console.log("Error!", error);
          this.loadingBudgets = false;
        });
    },
    getBudgetFundingStatuses() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      this.budgetFundingLoading = true;
      BudgetService.getBudgetFundingStatuses(this.magicLinkToken)
        .then(response => {
          console.log("KYB funding response ", response);
          this.budgetFundingLoading = false;

          this.fundingArray = response;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    // getPaymentRules() {
    //   WalletService.getPaymentRules()
    //     .then(response => {
    //       console.log("Rules: ", response);
    //       //Prune the returned budgets for the data we need for the table
    //       // var today = new Date();
    //       // var paymentRules = [];
    //       response.forEach(function(rule) {
    //         rule.futurePaymentRules = rule.PaymentRules.filter(rule =>
    //           moment(rule.startDate)
    //             .local()
    //             .isAfter(moment().local())
    //         ).map(x => {
    //           x.startDateFormatted = moment(x.startDate)
    //             .local()
    //             .format("dddd, MMMM Do, YYYY");
    //           x.displayName = x.User.displayName
    //             ? x.User.displayName
    //             : x.User.firstName + " " + x.User.lastName;
    //           return x;
    //         });
    //       });
    //       this.parentPaymentRules = response;
    //     })
    //     .catch(error => {
    //       console.log("Error!" + error);
    //     });
    // },
    resetKYB() {
      this.dialogKYB = false;
      this.$store.dispatch("setMarqeta");
    },
    loadPaymentsConsole() {
      this.dialogPaymentsConsole = true;
      this.dialogPaymentsWizard = false;
      var freshworks = document.querySelector("#launcher-frame");
      if (freshworks) {
        freshworks.style.right = "-55px";
        freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
      }
    },
    resetPaymentsConsole() {
      this.dialogPaymentsConsole = false;

      var freshworks = document.querySelector("#launcher-frame");
      if (freshworks) {
        freshworks.style.right = "22px";
      }

      if (this.previousRoute)
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps
        });
      else this.getScheduledPayments(0, true);
      // this.$refs.paymentsConsole.resetAllData();
    },
    loadPaymentsWizard() {
      this.$router.push({
        name: "paymentwizard",
        query: {
          previousRoute: this.$route.name
        },
        params: {
          //   preload: "wizard",
          previousRoute: this.$route.name
          // previousRouteProps: {

          // }
          //   oneTimePayment: true
        }
      });
      // this.dialogPaymentsWizard = true;
      // this.dialogPaymentsConsole = false;
      // var freshworks = document.querySelector("#launcher-frame");
      // if (freshworks) {
      //   freshworks.style.right = "-55px";
      //   freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
      // }
    },
    resetPaymentsWizard() {
      this.dialogPaymentsWizard = false;
      var freshworks = document.querySelector("#launcher-frame");
      if (freshworks) {
        freshworks.style.right = "22px";
      }
      // this.$refs.paymentsConsole.resetAllData();
      this.sendOneTimePayment = false;
      console.log("Previous route ", this.previousRoute);
      if (this.previousRoute) {
        this.$router.push({
          name: this.previousRoute,
          params: {
            previousRoute: this.$route.name
          }
        });
      } else if (this.magicLinkToken)
        this.$router.push({
          name: "wallet",
          params: {
            previousRoute: this.$route.name
          }
        });
      else {
        this.getBudgets();
        this.getScheduledPayments(0, true);
      }
    },
    loadKYB() {
      this.dialogKYB = true;
      this.resetPaymentAccountSetupDialog();
    },
    showPaymentAccountSetupDialog() {
      // if the user doesn't have a payments account setup, then we ask if they want to do the quick version
      // If the client doesn't exist already under Whistle, they haven't passed KYB, or submitted KYB, then we show the prompt
      if (
        (!this.marqeta.processingKYB && !this.marqeta.completedKYB) ||
        this.userProfile.clientId == 1
      ) {
        // If they don't exist in Marqeta at all, then we can proceed with the account setup.
        if (
          !this.clientFundingStatus ||
          !this.clientFundingStatus.existsInMarqeta
        ) {
          //show popup
          this.paymentAccountSetup.dialog = true;
        } else {
          this.dialogKYB = true;
        }
      }
    },
    resetPaymentAccountSetupDialog() {
      this.paymentAccountSetup = {
        dialog: false,
        success: false,
        error: false,
        loading: false
      };
    },
    async setupPaymentAccount() {
      var startTime = new Date();
      this.paymentAccountSetup.loading = true;
      try {
        var response = await MarqetaService.setupPaymentAccount({
          business_name_legal: this.userProfile.Client.clientName
        });
        console.log("Marqeta business account setup response", response);

        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff >= 1500) {
          timeDiff = 1500;
        }

        setTimeout(() => {
          this.paymentAccountSetup.loading = false;
          if (response.error_code) this.paymentAccountSetup.error = true;
          else this.paymentAccountSetup.success = true;
          this.getBudgetFundingStatuses();
        }, 1500 - timeDiff);
      } catch (err) {
        this.paymentAccountSetup.error = true;
      }
    },
    loadRecommendation(message) {
      if (!message.action) return;
      switch (message.action) {
        case "csv":
          this.loadPayoutForm();
          break;
        case "one-time-payment":
          this.sendOneTimePayment = true;
          this.loadPaymentsWizard();
          break;
        case "recurring-payment":
          console.log("handling budget program");
          this.loadPaymentsConsole();
          break;
        case "budget-wizard":
          this.loadBudgetWizard(message.label);
          break;
        case "admin-budget":
          console.log("handling admin budget");
          this.loadBudgetBuilder(null, false);
          break;
        case "budget-program":
          console.log("handling budget program");
          this.loadPaymentsConsole();
          break;
        case "kyb":
          console.log("loading kyb");
          this.showPaymentAccountSetupDialog();
          break;
        case "annual-reward-birthday":
          console.log("loading birthday reward");
          this.loadPaymentConfigWizard("BIRTHDAY");
          break;
        case "annual-reward-service-anniversary":
          console.log("loading service anniversary");
          this.loadPaymentConfigWizard("SERVICE_ANNIVERSARY");
          break;
      }
    },
    formatNum(x) {
      return numberWithCommas(x);
    },
    truncateNum(x, i) {
      return truncateNumber(x, i);
    },
    loadBudgetBuilder(budget, editing, parentBudget = null, preloadPanel) {
      this.showBudgetDialog = true;
      this.budget = budget;
      this.budgetForm.editing = editing;
      this.preloadParentBudget = parentBudget;
      this.preloadBudgetPanel = preloadPanel;
    },
    loadBudgetWizard(label = undefined) {
      this.$router.push({
        name: "budgetwizard",
        params: { previousRoute: "paymentadmin", paymentTileName: label }
      });
    },
    loadPaymentConfigWizard(type = undefined) {
      this.$router.push({
        name: "annualrewardwizard",
        params: { previousRoute: "paymentadmin", type }
      });
    },
    getAwardFulfillmentPartners() {
      console.log("Getting new award fulfillment partners");
      BudgetService.getAwardFulfillmentPartners()
        .then(response => {
          console.log("Award Fulfillment Partners: ", response);
          var partners = response.filter(
            x => x.status != "Deleted" && x.clientId == this.clientId
          );
          // We use $set because Vue wasn't updating this array that we passed as a prop to the Award Creator component
          this.$set(this, "awardFulfillmentPartners", partners);
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    handleViewChange(index) {
      this.activeView = index;
    },
    loadReporting() {
      this.$router.push({ name: "reporting" });
    },
    fundBudget(
      source,
      destination,
      preloadMenu,
      requestFunds = false,
      amount = null,
      budgetActivityId = null
    ) {
      console.log(source);
      console.log(destination);
      console.log(preloadMenu);
      console.log(requestFunds);
      console.log(amount);
      this.dialogPaymentMethods = true;
      this.sourceBudgetForFunding = source;
      this.destinationBudgetForFunding = destination;
      this.preloadPaymentMenu = preloadMenu;
      this.budgetFundingRequest = requestFunds;
      this.preloadFundingAmount = amount;
      this.preloadBudgetActivityId = budgetActivityId;
    },
    budgetColumns() {
      var array = [
        { text: "Name", value: "displayName", width: "30%" },
        // { text: "Total Balance", value: "totalAccountBalance", width: "25%" },
        { text: "Available Balance", value: "budgetBalance", width: "25%" }
        // { text: "", value: "icons", width: "10%", sortable: false },
        // { text: "Start Date", value: "formattedStartDate", width: "15%" },
        // { text: "Expiration", value: "endDate", width: "15%" },
      ];
      if (this.userProfile.clientId == 1)
        array.splice(1, 0, {
          text: "Id",
          value: "budgetId",
          width: "10%",
          align: "start"
        });

      // if (
      //   this.visibleBudgets.find((x) => x.permissions && x.permissions.owner) ||
      //   (this.permissions.includes("wallets:manualfund:budget") &&
      //     this.userProfile.clientId == 1)
      // ) {
      //   var balanceIndex = array.findIndex((x) => x.value == "budgetBalance");
      //   array.splice(balanceIndex + 1, 0, {
      //     text: "Funded",
      //     value: "funded",
      //     width: "15%",
      //     sortable: false,
      //   });
      // }
      // if (array.length == 4) {
      //   // We want to make the budget name bigger
      //   array[1].width = "40%";
      // }
      return array;
    },
    loadEditBudgetForm(budget) {
      if (!this.permissions.includes("budgets:create:budget")) {
        return;
      }
      console.log("Editing budget ", budget);
      // if (typeof budgetId == "object") {
      if (budget.permissions && !budget.permissions.owner) {
        return;
      }
      // budgetId = budgetId.budgetId;
      // }
      var editObj = JSON.parse(JSON.stringify(budget));
      this.budgetForm.editing = true;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Loading the selected budget";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      // BudgetService.getBudget(budgetId)
      //   .then((budget) => {
      //     console.log(budget);
      //     var editObj = JSON.parse(JSON.stringify(budget));
      var formattedStartDate = null;
      if (budget.startDate) {
        formattedStartDate = moment(budget.startDate)
          .local()
          .utc()
          .format("YYYY-MM-DD");
      }
      var formattedEndDate = null;
      if (budget.endDate !== null) {
        formattedEndDate = moment(budget.endDate)
          .local()
          .utc()
          .format("YYYY-MM-DD");
      } else {
        this.budgetForm.endDateDisabled = true;
        this.budgetForm.endDateCheckBox = true;
        this.budgetForm.forceValidForm = true;
      }
      editObj.startDate = formattedStartDate;
      editObj.endDate = formattedEndDate;

      // this.showBudgetDialog = true;
      this.showLoadingDialog = false;
      this.budgetForm.valid = true;
      console.log(JSON.parse(JSON.stringify(editObj)));
      this.loadBudgetBuilder(editObj, true);
      // })
      // .catch((error) => {
      //   this.showLoadingDialog = false;
      //   // this.resetBudgetForm();
      //   console.log("Error fetching budget! ", error);
      // });
    },
    async getScheduledPayments(page, reset = false) {
      try {
        if (reset) {
          page = 0;
        }
        if (page == 0) {
          this.paymentRuleTable.loading = true;
        }
        let clientId = this.clientId;
        let filter = `(status == 'Scheduled' || status == 'Pending'  || status == 'Draft') && ParentPaymentRule.status != 'Deleted' && clientId=${this.clientId}`;
        let limit = 50;
        let offset = page * limit;
        let sort = "startDate ASC";
        let expand = "User,ParentPaymentRule";
        let response = await WalletService.getPaymentRulesV2({
          filter: filter,
          limit: limit,
          offset: offset,
          sort: sort,
          expand: expand
        });
        if (clientId != this.clientId) {
          console.log("Ignoring request, changed client id");
          return;
        }
        console.log("response");
        console.log(response);
        let rows = response.result.rows;
        rows.forEach(row => {
          row.formattedStartDate = this.formatDate(row.startDate);
          if (row.User)
            row.User.displayName =
              row.User.displayName ||
              row.User.firstName + " " + row.User.lastName;
          switch (row.ParentPaymentRule.frequency) {
            case "ONE_TIME":
              row.frequency = "One time";
              break;
            case "MONTHLY":
              row.frequency = "Monthly";
              break;
            case "WEEKLY":
              row.frequency = "Weekly";
              break;
            case "DAILY":
              row.frequency = "Daily";
              break;
          }
          return row;
        });
        if (page == 0) {
          this.paymentRuleTable.paymentRules = rows;
        } else {
          this.paymentRuleTable.paymentRules = this.paymentRuleTable.paymentRules.concat(
            rows
          );
        }
        console.log(
          this.paymentRuleTable.paymentRules.length,
          response.result.count
        );
        if (
          Number(this.paymentRuleTable.paymentRules.length) <
          Number(response.result.count)
        ) {
          console.log("Getting more paymentRules");
          page = page + 1;
          this.getScheduledPayments(page, false);
        } else {
          this.paymentRuleTable.loading = false;
        }
      } catch (error) {
        console.log("error getting scheduled payments");
        console.log(error);
        this.paymentRuleTable.loading = false;
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date)
          .local()
          .format("MMM DD, YYYY h:mm a");
      } else {
        return null;
      }
    },
    loadBudgetActivity() {
      console.log("We're not really loading budget activity!");
    },
    refreshClientId(clientId) {
      console.log("New clientId ", clientId);
      this.clientId = clientId;
      this.getBudgets();
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "marqeta",
      "permissions",
      "clients",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleBudgets() {
      return this.budgets.filter(
        x =>
          x.clientId == this.clientId &&
          x.permissions &&
          (x.permissions.admin == true || x.permissions.owner == true) &&
          // !x.masterBudget &&
          x.status != "Deleted"
      );
    },
    clientFundingStatus() {
      if (this.userProfile.clientId && !this.budgetFundingLoading) {
        var status = this.fundingArray.find(
          x => x.clientId == this.userProfile.clientId
        );
        // if (!status)
        //   return {
        //     passedKYB: false,
        //     canFundMore: true,
        //     showLimitWarning: false,
        //     amountFunded: 0,
        //   };
        return status;
      }
      return null;
    },
    displayKYBButton() {
      return (
        (this.userProfile &&
          this.userProfile.clientId == 1 &&
          this.permissions.includes("marqeta:create:business")) ||
        (this.marqeta &&
          !this.marqeta.completedKYB &&
          !this.marqeta.processingKYB)
      );
    },
    // Randomized array of 3 messages
    visibleMessages() {
      const messages = [];
      for (let i = 0; i < this.messages.length && messages.length < 3; i++) {
        let message = this.messages[i];
        if (
          this.permissions.includes(message.permission) &&
          (message.action != "kyb" ||
            // Show KYB if they haven't passed KYB or submitted
            (message.action == "kyb" &&
              this.marqeta &&
              !this.marqeta.completedKYB &&
              !this.marqeta.processingKYB)) &&
          (message.action != "admin-budget" ||
            (message.action == "admin-budget" &&
              this.visibleBudgets.length == 0 &&
              !this.loadingBudgets))
        ) {
          messages.push(message);
        }
      }
      return messages;
    },
    accountBalance() {
      if (this.clientId !== null) {
        return this.budgets.find(
          x => x.clientId == this.clientId && x.masterBudget
        );
      }
      return null;
    },
    pendingTransfersTotal() {
      var sum = 0;
      this.budgets
        .filter(x => x.clientId == this.clientId)
        .forEach(budget => {
          budget.BudgetActivities.filter(
            x => x.stripeStatus == "pending" && x.status == "Active"
          ).forEach(x => {
            sum += x.amount;
          });
        });
      return sum;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    displayCSVPaymentWizard() {
      return (
        this.permissions.includes("wallets:create:cashpayment") &&
        !(
          this.userProfile &&
          this.userProfile.Client &&
          !!this.userProfile.Client.annualPaymentCap
        )
      );
    }
  },
  watch: {
    "$route.query": function(newVal, oldVal) {
      // If the query was changed and we just closed plaid's oauth, then we came from the payment wizard so we load that up
      if (!newVal.oauth_state_id && oldVal.oauth_state_id) {
        this.loadPaymentsWizard();
      }
    },
    // If old val was null it is the first time loading
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getBudgets();
        this.getScheduledPayments(0, true);
      }
    },
    dialogPaymentMethods: function(newVal) {
      if (newVal === false) {
        // side drawer just closed so we refresh budgets in case they have funded
        console.log("Refreshing budgets");
        this.getBudgets();
      }
    }
  }
};
</script>

<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.brand-background {
  background-color: var(--v-brand-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.primary-background {
  background-color: var(--v-primary-base);
}
.pink-background {
  background-color: var(--v-brandPink-base);
}
.border-radius {
  border-radius: 5px;
}
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 102px;
}
.top-right-icon {
  position: absolute;
  z-index: 1;
  right: 2px;
  top: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 100%;
  transition: 0.2s opacity;
}
.card-letters {
  font-size: 20px;
  width: 45px;
  min-width: 45px;
}
.half-width {
  width: 50%;
}
.checkmark {
  padding: 10px;
}
.header-text {
  font-size: x-large;
}

.large-card-button {
  min-width: 210px;
  margin: 0 5px 0 5px;
  width: calc(calc(100% / 3) - 10px);
}

.mobile-card-button {
  width: 100%;
}

/* Hides gray background when clicking v-card */
.v-card--link:before {
  background: none;
}

.card-header {
  /* max-height: 60px; */
  height: fit-content;
}

.robin-icon {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
}

.icon {
  min-width: 52px;
  max-width: 52px;
  width: 52px;
  min-height: 52px;
  max-height: 52px;
  height: 52px;
}

.action-icon {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  height: 40px;
}

.page-title {
  font-size: 1.7em;
}
/* Changes the default padding of all cols that have a card in them in the payment manager div */
#payment-manager .card-col.col {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 960px) {
  .icon {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  }

  .page-title {
    font-size: 1.2em;
  }

  #payment-manager .card-col.col {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

#payment-manager .v-card {
  border-radius: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

/* #payment-manager .v-expansion-panel-header {
  width: 96%;
} */

.view-button {
  line-height: 1.5;
  border-radius: 50px;
  height: 40px;
  margin-bottom: 16px;
  margin-top: 10px;
  padding: 0 0 0 16px !important;
}

.highlighted-button {
  background: #eeeeee !important;
}

.view-button >>> .v-btn__content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.action-button {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.dark-green-text {
  color: var(--v-brandDarkGreen-base);
}

.pointer-rows >>> tr {
  cursor: pointer;
}

.bold-font {
  font-family: "Roboto", sans-serif !important;
}

.card-header >>> .rec-header {
  font-weight: normal !important;
}
</style>
